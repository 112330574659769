<div class="page" *ngIf="validhash">
    <div class="container">
      <div class="left">
        <div class="row">
          <div class="col-12">
            <img src="../../assets/images/2020_GameOn.png" alt="Game On 2020" class="img-responsive gameOn">
          </div>
        </div>
        <div class="login" *ngIf="!passwordResetValid">
          Reset your Password
        </div>
        <div class="eula" *ngIf="!isIncorrect && !passwordResetValid && !serverError && !passwordMismatch">
          Password must be 8 characters. 
        </div>
        <div class="eula invalid" *ngIf="isIncorrect && !passwordResetValid && !serverError  && !passwordMismatch">
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>
          The password enter is either not valid or less than 8 characters. Please try again.
        </div>
        <div class="eula invalid" *ngIf="!isIncorrect && !passwordResetValid && !serverError  && passwordMismatch">
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>
          The password entered is not matching with confirm password.
        </div>
        <div class="eula invalid" *ngIf="!isIncorrect && serverError  && !passwordMismatch">
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>
          Oops!!! We faced some error while connecting to server. Please try again
        </div>
        <div class="eula valid" *ngIf="passwordResetValid && !isIncorrect">
          <fa-icon [icon]="faCheckSquare"></fa-icon>
          Password has been reset. Please login in by clicking 
          <span class="link" (click)="navigateToLogin()">HERE</span>
        </div>
        
      </div>
      <div class="right">
        <div class="form">
          <label for="email"><fa-icon [icon]="faKey"></fa-icon> New Password</label>
          <input type="password" id="password" class="border-bottom" [(ngModel)]="password" (keydown.enter)="submit()">
          <label for="email"><fa-icon [icon]="faKey"></fa-icon> Confirm Password</label>
          <input type="password" id="confirmPassword" class="border-bottom" [(ngModel)]="confirmPassword" (keydown.enter)="submit()">
          <label for="forget" class="forgot" (click)="navigateToLogin()">Sign In</label>
          <input type="submit" id="submit" value="Submit" (click)="submit()" (keydown.enter)="submit()" *ngIf="!disabled">
          <div *ngIf="disabled">
            <div class="loader"> </div>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="" *ngIf="!validhash">
  <div class="mars"></div>
    <img src="https://mars-404.templateku.co/img/404.svg" class="logo-404" />
    <img src="https://mars-404.templateku.co/img/meteor.svg" class="meteor" />
    <p class="title">Oh no!!!</p>
    <p class="subtitle">
      The link is either broken or has expired.
    </p>
    <div align="center">
      <button type="button" class="btn btn-outline-dark" [routerLink]="['/login']">Back to Login</button>
    </div>
    <img src="https://mars-404.templateku.co/img/astronaut.svg" class="astronaut" />
    <img src="https://mars-404.templateku.co/img/spaceship.svg" class="spaceship" />
</div>
  