import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SupplierService } from './supplier.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public supplierService: SupplierService, public router: Router) { }

  canActivate(): boolean {
    if (!this.supplierService.isAuth()) {
        this.router.navigateByUrl('/login');
        return false;
    }
    return true;
}
}
