import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupplierService } from '../services/supplier.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  step;
  authPage = false;
  disabled = false;
  emailvalid = false;
  isIncorrect = false;
  isFailed = false;
  isAuthFailed = false;
  body: any;
  loginData: any;
  email = '';
  authcode = '';
  notBotUser = false;
  resendStart = false;

  constructor(private router: Router, private supplierService: SupplierService) {
    this.step = 0;
  }

  ngOnInit() {
  }

  public resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.notBotUser = true;
    }
  }

  toggle() {
    this.step = 1;
    this.isAuthFailed = false;
    this.isIncorrect = false;
    this.isFailed = false;
    this.authPage = !this.authPage
  }

  navigateToHome() {
    this.router.navigateByUrl('/home');
  }

  validateEmail(e) {
    var re = /\S+@\S+\.\S+/;
    return re.test(e);
  }

  //logic for adding new user bypass authcode

  submitdata() {
    if (this.email === 'mfa86518.slot@whsec.us' || this.email === 'pentesterone@bjs.com') {
      this.submit();
    }
  }

  submit() {
    this.emailvalid = this.validateEmail(this.email);
    if (this.emailvalid) {
      this.isIncorrect = false;
      this.callAuth();
    } else {
      this.isIncorrect = true;
    }
  }

  callAuth() {
    this.isFailed = false;
    this.disabled = true;
    this.body = {
      "userEmail": this.email.toLowerCase(),
    }
    this.supplierService.sendLoginCode(this.body).subscribe(
      (data) => {
        if (data['email'] == 'sent') {
          this.disabled = false;
          this.toggle();
        } else {
          this.isFailed = true;
          this.disabled = false;
        }
      },
      (error) => {
        this.isFailed = true;
        this.disabled = false;
        console.log(error);
      }
    );
  }

  resend() {
    this.resendStart = true;
    this.body = {
      "userEmail": this.email.toLowerCase(),
    }
    this.supplierService.sendLoginCode(this.body).subscribe(
      (data) => {
        if (data['email'] == 'sent') {
          this.resendStart = false;
        }
      },
      (error) => {
      }
    );
  }

  login() {
    this.isAuthFailed = false;
    this.disabled = true;
    this.loginData = {};
    this.body = {
      "email": this.email.toLowerCase(),
      "auth_code": this.authcode
    }
    this.supplierService.login(this.body).subscribe(
      (data) => {
        this.loginData = data;
        if (this.loginData.LoginSucessful == 'Success') {
          sessionStorage.setItem("change_supplier", this.loginData.USER[0].change_supplier);
          sessionStorage.setItem("username", this.loginData.USER[0].user_name);
          sessionStorage.setItem("company", this.loginData.USER[0].supplier_name);
          sessionStorage.setItem("isLoggedIn", 'Y');
          this.navigateToHome()
        } else {
          this.isAuthFailed = true;
          sessionStorage.setItem("isLoggedIn", 'N');
        }
        this.disabled = false;
      },
      (error) => {
        this.isAuthFailed = true;
        this.disabled = false;
        console.log(error);
      }
    );
  }
}