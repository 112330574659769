import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  isLoggedIn = 'N'
  constructor(private httpClient: HttpClient) { }

  isAuth() {
    this.isLoggedIn = sessionStorage.getItem('isLoggedIn');
    if (this.isLoggedIn == 'Y') {
      return true;
    }
    return false;
  }

  env() {
    return environment.host;
  }

  login(body) {
    let URL = environment.host + environment.envConst.login;
    return this.httpClient.post(URL, body);
  }

  sendLoginCode(body) {
    let URL = environment.host + environment.envConst.sendLoginCode;
    return this.httpClient.post(URL, body);
  }

  sendResetPasswordLink(body) {
    let URL = environment.host + environment.envConst.sendResetPasswordLink;
    return this.httpClient.post(URL, body);
  }

  validateHash(body) {
    let URL = environment.host + environment.envConst.validateHash;
    return this.httpClient.post(URL, body);
  }

  passwordReset(body) {
    let URL = environment.host + environment.envConst.passwordReset;
    return this.httpClient.post(URL, body);
  }

  logout() {
    let body = {}
    let URL = environment.host + environment.envConst.logout;
    return this.httpClient.post(URL, body);
  }

  sendFeedbackMessage(e) {
    let URL = environment.host + environment.envConst.sendFeedbackMessage;
    return this.httpClient.post(URL, e);
  }

  activity(e) {
    let URL = environment.host + environment.envConst.activity + e;
    return this.httpClient.get(URL);
  }

  activityf(e) {
    let URL = environment.host + environment.envConst.activityf + e;
    return this.httpClient.get(URL);
  }

  getTimePeriods() {
    let URL = environment.host + environment.envConst.getTimePeriods;
    return this.httpClient.get(URL);
  }

  getDataRefreshTime() {
    let URL = environment.host + environment.envConst.getDataRefreshTime;
    return this.httpClient.get(URL);
  }

  getSupplierList() {
    let URL = environment.host + environment.envConst.getSupplierList;
    return this.httpClient.get(URL);
  }

  getLastDate() {
    // YYYY-MM-DD
    return "2019-02-03"
  }

  getSupplierRevenueOrderTrend(body) {
    let URL = environment.host + environment.envConst.getSupplierRevenueOrderTrend;
    return this.httpClient.post(URL, body);
  }

  getSupplierRevenueOrderTrendFulfill(body) {
    let URL = environment.host + environment.envConst.getSupplierRevenueOrderTrendFulfill;
    return this.httpClient.post(URL, body);
  }

  getLevel4Mapping(body) {
    let URL = environment.host + environment.envConst.getLevel4Mapping;
    return this.httpClient.post(URL, body);
  }

  getSupplierPenetrationFulfilled(body) {
    let URL = environment.host + environment.envConst.getSupplierPenetrationFulfilled;
    return this.httpClient.post(URL, body);
  }

  getSupplierSalesPerformanceFulfilled(body) {
    let URL = environment.host + environment.envConst.getSupplierSalesPerformanceFulfilled;
    return this.httpClient.post(URL, body);
  }

  getSupplierRatingFulfilled(body) {
    let URL = environment.host + environment.envConst.getSupplierRatingFulfilled;
    return this.httpClient.post(URL, body);
  }

  getSupplierDigitalChannelStatsFulfilled(body) {
    let URL = environment.host + environment.envConst.getSupplierDigitalChannelStatsFulfilled;
    return this.httpClient.post(URL, body);
  }

  getSupplierCategoryPerformanceFulfilled(body) {
    let URL = environment.host + environment.envConst.getSupplierCategoryPerformanceFulfilled;
    return this.httpClient.post(URL, body);
  }

  getSupplierPenetration(body) {
    let URL = environment.host + environment.envConst.getSupplierPenetration;
    return this.httpClient.post(URL, body);
  }

  getSupplierSalesPerformance(body) {
    let URL = environment.host + environment.envConst.getSupplierSalesPerformance;
    return this.httpClient.post(URL, body);
  }

  getSupplierDigitalChannelStatsItemTotal(body) {
    let URL = environment.host + environment.envConst.getSupplierDigitalChannelStatsItemTotal;
    return this.httpClient.post(URL, body);
  }
  getSupplierRating(body) {
    let URL = environment.host + environment.envConst.getSupplierRating;
    return this.httpClient.post(URL, body);
  }
  getSupplierDigitalChannelStats(body) {
    let URL = environment.host + environment.envConst.getSupplierDigitalChannelStats;
    return this.httpClient.post(URL, body);
  }
  getSupplierCategoryPerformance(body) {
    let URL = environment.host + environment.envConst.getSupplierCategoryPerformance;
    return this.httpClient.post(URL, body);
  }
  getConversionTraffic(body) {
    let URL = environment.host + environment.envConst.getConversionTraffic;
    return this.httpClient.post(URL, body);
  }
  getSupplierMembers(body) {
    let URL = environment.host + environment.envConst.getSupplierMembers;
    return this.httpClient.post(URL, body);
  }
  getSupplierFunnel(body) {
    let URL = environment.host + environment.envConst.getSupplierFunnel;
    return this.httpClient.post(URL, body);
  }
  getSupplierTotalOrderPopup(body) {
    let URL = environment.host + environment.envConst.getSupplierTotalOrderPopup;
    return this.httpClient.post(URL, body);
  }
  public getSupplierTotalOrderPopupFulfilled(body) {
    let URL = environment.host + environment.envConst.getSupplierTotalOrderPopupFulfilled;
    return this.httpClient.post(URL, body);
  }
  public getSupplierRatingPopup(body) {
    let URL = environment.host + environment.envConst.getSupplierRatingPopup;
    return this.httpClient.post(URL, body);
  }
  public getSupplierRatingPopupFulfilled(body) {
    let URL = environment.host + environment.envConst.getSupplierRatingPopupFulfilled;
    return this.httpClient.post(URL, body);
  }
}
