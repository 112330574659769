import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { SupplierService } from '../services/supplier.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  getTimePeriods: any;
  errorDate = '';
  errorDateNeeded = '';
  minDate = '';
  maxDate = '';
  startDateInput = '';
  endDateInput = '';
  timePeriodChange = false;
  typeChanged = false;
  customDateModal = false;
  faExclamationTriangle = faExclamationTriangle;
  faTimes = faTimes;
  faCalendarDay = faCalendarDay;
  timePeriod = 'YESTERDAY';
  type = 'D';
  customDate: any;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',
    firstDayOfWeek: 'su'
  };
  startDate: IMyDateModel = null;
  endDate: IMyDateModel = null;
  constructor(private router: Router, private supplierService: SupplierService) {
    this.getTimePeriods = [];
    this.supplierService.getTimePeriods().subscribe(
      (data) => {
        this.getTimePeriods = data;
        this.minDate = this.getTimePeriods[0].ly_ytd_start_date;
        this.maxDate = this.getTimePeriods[0].ty_ytd_end_date;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onDateChangedStart(event: IMyDateModel): void {
    // date selected
    this.startDateInput = event.singleDate.formatted
  }

  onDateChangedEnd(event: IMyDateModel): void {
    // date selected
    this.endDateInput = event.singleDate.formatted
  }

  ngOnInit() {
    this.timePeriodChange = true;
    this.typeChanged = true;
  }

  close() {
    this.customDateModal = !this.customDateModal;
  }

  openCustomModal() {
    this.customDateModal = !this.customDateModal;
  }


  DateCompare(DateA, DateB) {
    var a = new Date(DateA);
    var b = new Date(DateB);

    let msDateA = parseInt(a.getFullYear().toString() + ("0" + (a.getMonth() + 1)).slice(-2) + ("0" + a.getDate()).slice(-2))
    let msDateB = parseInt(b.getFullYear().toString() + ("0" + (b.getMonth() + 1)).slice(-2) + ("0" + b.getDate()).slice(-2))

    if ((msDateA) < (msDateB))
      return -1;  // less than
    else if ((msDateA) == (msDateB))
      return 0;  // equal
    else if ((msDateA) > (msDateB))
      return 1;  // greater than
    else
      return null;  // error
  }

  customSearch() {
    this.errorDate = '';
    this.errorDateNeeded = '';
    if (!this.startDateInput || !this.endDateInput) {
      this.errorDateNeeded = 'Start date and End date are both mandatory';
    } else {
      if (this.DateCompare(this.startDateInput, this.endDateInput) > 0) {
        this.errorDateNeeded = 'Start date cannot be greater than End date';
      } else if (this.DateCompare(this.startDateInput, this.minDate) < 0) {
        this.errorDate = `Date needs to be between`;
      } else if (this.DateCompare(this.startDateInput, this.maxDate) > 0) {
        this.errorDate = `Date needs to be between`;
      } else if (this.DateCompare(this.endDateInput, this.minDate) < 0) {
        this.errorDate = `Date needs to be between`;
      } else if (this.DateCompare(this.endDateInput, this.maxDate) > 0) {
        this.errorDate = `Date needs to be between`;
      } else if (this.startDateInput == "2020-02-01" || this.startDateInput == "2020-01-31") {
        this.errorDate = `Date needs to be between`;
      }
      else {
        this.changeTimeperiod('Custom');
        this.close()
      }
    }
  }

  changeTimeperiod(e) {
    this.timePeriodChange = false;
    this.timePeriod = e;
    if (e == 'Custom') {
      this.customDate = {};
      this.customDate = {
        'startDate': this.startDateInput,
        'endDate': this.endDateInput
      }
    }
    this.timePeriodChange = true;
  }

  changeType(e) {
    this.typeChanged = false;
    this.type = e;
    this.typeChanged = true;
  }


}
