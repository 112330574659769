<div class="sidebar noPrint">
    <div class="row full">
        <div class="col-12 my-2 box1">
            <img src="https://s7d6.scene7.com/is/image/bjs/bjs_logo_update_060618?$Desktop%20Logo$&fmt=png-alpha"
                class="img-fluid mx-auto d-block" alt="BJ's Logo">
        </div>
        <div class="col-12 box2">
            <hr class="lines">
            <ul class="p-0 m-0">
                <li class="datesList">
                    <p class="text-center dates active" [ngClass]="{'active': type == 'D'}" (click)="changeType('D')">
                        Demand
                    </p>
                </li>
                <li class="datesList">
                    <p class="text-center dates" [ngClass]="{'active': type == 'F'}" (click)="changeType('F')">
                        Fulfill
                    </p>
                </li>
            </ul>
            <hr class="lines mb-4">
            <hr class="lines mt-4">
            <ul class="p-0 m-0">
                <li class="datesList">
                    <p class="text-center dates" [ngClass]="{'active': timePeriod == 'YESTERDAY'}"
                        (click)="changeTimeperiod('YESTERDAY')">
                        Yesterday
                    </p>
                </li>
                <li class="datesList">
                    <p class="text-center dates" [ngClass]="{'active': timePeriod == 'WTD'}"
                        (click)="changeTimeperiod('WTD')">
                        WTD
                    </p>
                </li>
                <li class="datesList">
                    <p class="text-center dates" [ngClass]="{'active': timePeriod == 'MTD'}"
                        (click)="changeTimeperiod('MTD')">
                        MTD
                    </p>
                </li>
                <li class="datesList">
                    <p class="text-center dates" [ngClass]="{'active': timePeriod == 'QTD'}"
                        (click)="changeTimeperiod('QTD')">
                        QTD
                    </p>
                </li>
                <li class="datesList">
                    <p class="text-center dates" [ngClass]="{'active': timePeriod == 'YTD'}"
                        (click)="changeTimeperiod('YTD')">
                        YTD
                    </p>
                </li>
                <li class="datesList">
                    <p class="text-center dates" [ngClass]="{'active': timePeriod == 'Custom'}"
                        (click)="openCustomModal()">
                        Custom
                    </p>
                </li>
            </ul>

            <hr class="lines">
        </div>
    </div>
</div>

<div class="content" *ngIf="timePeriodChange">
    <app-content [timePeriod]='timePeriod' [type]='type' [customDate]='customDate' [getTimePeriods]='getTimePeriods'>
    </app-content>
</div>


<div class="modal" [ngClass]="{'modalDisplay': customDateModal}">
    <div class="modal-dialog modal-width-60">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row noMar">
                    <div class="col-10">
                        <div class="header">
                            Choose your date range
                            <p class="customDates">
                                ( Min: {{minDate | date}} to Max: {{maxDate | date}} )
                            </p>
                        </div>
                    </div>
                    <div class="col-2">
                        <button type="button" class="close active" data-dismiss="modal"
                            (click)="close()">&times;</button>
                    </div>
                    <div class="col-12">
                        <p class="customDates">
                            Start Date:
                        </p>
                    </div>
                    <div class="col-12">
                        <div class="input-group">
                            <!-- input box -->
                            <input class="form-control" name="startDate" placeholder="Select a date(YYYY-MM-DD)"
                                angular-mydatepicker #startCal="angular-mydatepicker" [(ngModel)]="startDate"
                                [options]="myDpOptions" (dateChanged)="onDateChangedStart($event)" />

                            <!-- clear date button -->
                            <div class="input-group-append">
                                <button type="button" class="btn btn-secondary" *ngIf="startDate"
                                    (click)="startCal.clearDate()">
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </button>
                            </div>

                            <!-- toggle calendar button -->
                            <div class="input-group-append">
                                <button type="button" class="btn btn-secondary" (click)="startCal.toggleCalendar()">
                                    <fa-icon [icon]="faCalendarDay"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <p class="customDates">
                            End Date:
                        </p>
                    </div>
                    <div class="col-12">
                        <div class="input-group">
                            <!-- input box -->
                            <input class="form-control" name="endDate" placeholder="Select a date(YYYY-MM-DD)"
                                angular-mydatepicker #endCal="angular-mydatepicker" [(ngModel)]="endDate"
                                [options]="myDpOptions" (dateChanged)="onDateChangedEnd($event)" />

                            <!-- clear date button -->
                            <div class="input-group-append">
                                <button type="button" class="btn btn-secondary" *ngIf="endDate"
                                    (click)="endCal.clearDate()">
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </button>
                            </div>

                            <!-- toggle calendar button -->
                            <div class="input-group-append">
                                <button type="button" class="btn btn-secondary" (click)="endCal.toggleCalendar()">
                                    <fa-icon [icon]="faCalendarDay"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="errorDate != ''">
                        <p class="customDates text-center mt-4">
                            <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                            {{errorDate}} {{ minDate | date}} to {{ maxDate | date}}
                        </p>
                    </div>
                    <div class="col-12" *ngIf="errorDateNeeded != ''">
                        <p class="customDates text-center mt-4">
                            <fa-icon [icon]="faExclamationTriangle"></fa-icon> {{errorDateNeeded}}
                        </p>
                    </div>
                    <div class="col-12 mt-4">
                        <button type="button" class="btn btn-outline-light btn-block"
                            (click)="customSearch()">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>