<div class="mars"></div>
<img src="https://mars-404.templateku.co/img/404.svg" class="logo-404" />
<img src="https://mars-404.templateku.co/img/meteor.svg" class="meteor" />
<p class="title">Oh no!!!</p>
<p class="subtitle">
	You’re either misspelling the URL <br /> or requesting a page that's no longer here.
</p>
<div align="center">
	<button type="button" class="btn btn-outline-dark" [routerLink]="['/login']">Back to Login</button>
</div>
<img src="https://mars-404.templateku.co/img/astronaut.svg" class="astronaut" />
<img src="https://mars-404.templateku.co/img/spaceship.svg" class="spaceship" />