import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { faCaretDown, faStar, faInfoCircle, faCommentDots, faDownload, faSignOutAlt, faQuestionCircle, faPrint, faSquareFull } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { SupplierService } from '../services/supplier.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  @Input() timePeriod;
  @Input() type;
  @Input() customDate;
  @Input() getTimePeriods;
  trendType = "REV";
  getSuppname = '';
  activityURL = "";
  body: any;
  selectedL4 = '';
  L4Loaded = false;
  message = '';
  getSupplierRevenueOrderTrend: any;
  getSupplierRevenueOrderTrendLoaded = false;
  change_supplier = 'N';
  getSupplierDigitalChannelStatsEmpty = false;
  username = '';
  getSupplierRevenueOrderTrendFulfill: any;
  getSupplierRevenueOrderTrendFulfillLoaded = false;
  supplierName = '';
  downloadUrl = '';
  faCaretDown = faCaretDown;
  getSupplierTotalOrderPopupLoaded = true;
  getSupplierRatingPopupLoaded = true;
  faCommentDots = faCommentDots;
  faDownload = faDownload;
  suppName = '';
  searchText = '';
  faPrint = faPrint;
  faSquareFull = faSquareFull;
  showActiveItemToolTip = false;
  faQuestionCircle = faQuestionCircle;
  faInfoCircle = faInfoCircle;
  faStar = faStar;
  farStar = farStar;
  faSignOutAlt = faSignOutAlt;
  datarefresh = false;
  showDigitalModal = false;
  salesDataLoaded = false;
  getSupplierRatingLoaded = false;
  getSupplierDigitalChannelStatsLoaded = false;
  getSupplierCategoryPerformanceLoaded = false;
  getConversionTrafficLoaded = false;
  getSupplierMembersLoaded = false;
  showMemberPercToolTip = true;
  openFeedbackModal = false;
  openRatingsModal = false;
  getSupplierRatingPopup: any;
  openOrdersModal = false;
  getSupplierTotalOrderPopup: any;
  ordModalOrdDesc = false;
  ordModalOrdAsc = false;
  ordModalRevAsc = false;
  ordModalRevDesc = true;
  ordModalUnitsAsc = false;
  ordModalUnitsDesc = false;
  getConversionTrafficEmpty = false;
  PDPDesc = false;
  PDPAsc = false;
  REVDesc = true;
  REVAsc = false;
  UNITDesc = false;
  UNITAsc = false;
  ORDDesc = false;
  ORDAsc = false;
  ARTDesc = false;
  convDesc = false;
  convAsc = false;
  ARTAsc = false;
  RATEDesc = false;
  RATEAsc = false;
  unitRatModalDesc = true;
  unitRatModalAsc = false;
  revRatModalDesc = false;
  revRatModalAsc = false;
  sort = true;
  openDropdown = false;
  getSupplierList: any;
  getSupplierPenetration: any;
  getDataRefreshTime: any;
  getSupplierSalesPerformance: any;
  getSupplierDigitalChannelStatsItemTotal: any;
  getSupplierRating: any;
  getSupplierDigitalChannelStats: any;
  getSupplierCategoryPerformance: any;
  getConversionTraffic: any;
  getSupplierMembers: any;
  getSupplierFunnel: any;
  getSupplierFunnelTotal = 0;
  pdpWidth = 0;
  atcWidth = 0;
  orderWidth = 0;
  totalUnitsDemand = 0;
  totalProd = 0;
  totalReviews = 0;
  totalRev = 0;
  total_articles = 0;
  active_articles = 0;
  bopic_articles = 0;
  sdd_articles = 0;
  response: any;
  getLevel4Mapping: any;

  revtitle = '';
  ordertitle = '';
  charttype = 'LineChart';
  revchartdata: any;
  orderchartdata: any;
  revcolumnNames = ["Fiscal Weeks", "Revenue", "Revenue Last Year"];
  ordercolumnNames = ["Fiscal Weeks", "Orders", "Orders Last Year"];
  options = {
    hAxis: {
      title: '',
      textStyle: {
        color: '#FFF',
        fontSize: 10
      },
      titleTextStyle: {
        color: "#FFF",
        fontName: "sans-serif",
        fontSize: 16,
        bold: true,
        italic: false
      }
    },
    vAxis: {
      title: 'Revenue in $',
      stacked: false,
      textStyle: {
        color: '#FFF',
        fontSize: 10
      },
      titleTextStyle: {
        color: "#FFF",
        fontName: "sans-serif",
        fontSize: 16,
        bold: true,
        italic: false
      }
    },
    pointSize: 5,
    legend: 'none',
    colors: ["#81c784", "#e57373"],
    backgroundColor: '#474a59',
    legendTextStyle: { color: '#FFF' },
    titleTextStyle: {
      color: '#FFF',
      fontSize: 0
    },
  };
  orderoptions = {
    hAxis: {
      title: '',
      textStyle: {
        color: '#FFF',
        fontSize: 10
      },
      titleTextStyle: {
        color: "#FFF",
        fontName: "sans-serif",
        fontSize: 16,
        bold: true,
        italic: false
      }
    },
    vAxis: {
      title: 'Orders ',
      stacked: false,
      textStyle: {
        color: '#FFF',
        fontSize: 10
      },
      titleTextStyle: {
        color: "#FFF",
        fontName: "sans-serif",
        fontSize: 16,
        bold: true,
        italic: false
      }
    },
    pointSize: 5,
    legend: 'none',
    colors: ["#81c784", "#e57373"],
    backgroundColor: '#474a59',
    legendTextStyle: { color: '#FFF' },
    titleTextStyle: {
      color: '#FFF',
      fontSize: 0
    },
  };
  height = 250;
  width = 550;


  constructor(private supplierService: SupplierService, private router: Router) {
    this.getDataRefreshTime = [];
    this.supplierName = sessionStorage.getItem("company");
    this.suppName = this.supplierName;
    this.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    this.width = Number((this.width));
    this.change_supplier = sessionStorage.getItem("change_supplier");
    this.username = sessionStorage.getItem("username");
    this.supplierService.getDataRefreshTime().subscribe((data) => {
      this.getDataRefreshTime = data;
      if (this.getDataRefreshTime[0].banner_flag == 'Y') {
        this.datarefresh = true;
      } else {
        this.datarefresh = false;
      }
    }, (error) => {
      if (error.status == 401) {
        this.logout();
      }
      console.log(error);
    })


  }

  ngOnInit() {

  }

  feedback() {
    this.openFeedbackModal = !this.openFeedbackModal;
  }

  sortRatingModal(e) {
    if (e === 'UNITS' && this.unitRatModalDesc === false) {
      if (this.type == 'D') {
        this.getSupplierRatingPopup.sort((a, b) => parseFloat(b.units_d) - parseFloat(a.units_d));
      } else if (this.type == 'F') {
        this.getSupplierRatingPopup.sort((a, b) => parseFloat(b.units_f) - parseFloat(a.units_f));
      }
      this.unitRatModalDesc = true;
      this.unitRatModalAsc = false;
      this.revRatModalDesc = false;
      this.revRatModalAsc = false;

    } else if (e === 'UNITS' && this.unitRatModalDesc === true) {
      if (this.type == 'D') {
        this.getSupplierRatingPopup.sort((a, b) => parseFloat(a.units_d) - parseFloat(b.units_d));
      } else if (this.type == 'F') {
        this.getSupplierRatingPopup.sort((a, b) => parseFloat(a.units_f) - parseFloat(b.units_f));
      }
      this.unitRatModalDesc = false;
      this.unitRatModalAsc = true;
      this.revRatModalDesc = false;
      this.revRatModalAsc = false;

    }
    if (e === 'REVIEW' && this.revRatModalDesc === false) {
      this.getSupplierRatingPopup.sort((a, b) => parseFloat(b.reviews) - parseFloat(a.reviews));
      this.unitRatModalDesc = false;
      this.unitRatModalAsc = false;
      this.revRatModalDesc = true;
      this.revRatModalAsc = false;

    } else if (e === 'REVIEW' && this.revRatModalDesc === true) {
      this.getSupplierRatingPopup.sort((a, b) => parseFloat(a.reviews) - parseFloat(b.reviews));
      this.unitRatModalDesc = false;
      this.unitRatModalAsc = false;
      this.revRatModalDesc = false;
      this.revRatModalAsc = true;
    }
  }

  ordersModal() {
    this.openOrdersModal = false;
    this.ordModalUnitsDesc = false;
    this.ordModalUnitsAsc = false;
    this.ordModalRevDesc = true;
    this.ordModalRevAsc = false;
    this.ordModalOrdDesc = false;
    this.ordModalOrdAsc = false;
    this.getSupplierTotalOrderPopup = [];
    this.getSupplierTotalOrderPopupLoaded = false;
    if (this.type == 'D') {
      this.supplierService.getSupplierTotalOrderPopup(this.body).subscribe(
        (data) => {
          this.getSupplierTotalOrderPopup = data['SUPPLIER_TOTAL_ORDER_POPUP'];
        },
        (error) => {
          if (error.status == 401) {
            this.logout();
          }
          console.log(error);
        }
      );
    } else if (this.type == 'F') {
      this.supplierService.getSupplierTotalOrderPopupFulfilled(this.body).subscribe(
        (data) => {
          this.getSupplierTotalOrderPopup = data['SUPPLIER_TOTAL_ORDER_POPUP_FULFILLED'];
        },
        (error) => {
          if (error.status == 401) {
            this.logout();
          }
          console.log(error);
        }
      );
    }
    this.getSupplierTotalOrderPopupLoaded = true;
    this.openOrdersModal = true;
  }

  sortOrderModal(e) {
    if (e === 'UNITS' && this.ordModalUnitsDesc === false) {
      if (this.type == 'D') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(b.ty_demand_quantity) - parseFloat(a.ty_demand_quantity));
      } else if (this.type == 'F') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(b.ty_fulfilled_quantity) - parseFloat(a.ty_fulfilled_quantity));
      }
      this.ordModalUnitsDesc = true;
      this.ordModalUnitsAsc = false;
      this.ordModalRevDesc = false;
      this.ordModalRevAsc = false;
      this.ordModalOrdDesc = false;
      this.ordModalOrdAsc = false;

    } else if (e === 'UNITS' && this.ordModalUnitsDesc === true) {
      if (this.type == 'D') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(a.ty_demand_quantity) - parseFloat(b.ty_demand_quantity));
      } else if (this.type == 'F') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(a.ty_fulfilled_quantity) - parseFloat(b.ty_fulfilled_quantity));
      }
      this.ordModalUnitsDesc = false;
      this.ordModalUnitsAsc = true;
      this.ordModalRevDesc = false;
      this.ordModalRevAsc = false;
      this.ordModalOrdDesc = false;
      this.ordModalOrdAsc = false;
    }
    if (e === 'REV' && this.ordModalRevDesc === false) {
      if (this.type == 'D') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(b.ty_demand_revenue) - parseFloat(a.ty_demand_revenue));
      } else if (this.type == 'F') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(b.ty_fulfilled_revenue) - parseFloat(a.ty_fulfilled_revenue));
      }
      this.ordModalUnitsDesc = false;
      this.ordModalUnitsAsc = false;
      this.ordModalRevDesc = true;
      this.ordModalRevAsc = false;
      this.ordModalOrdDesc = false;
      this.ordModalOrdAsc = false;

    } else if (e === 'REV' && this.ordModalRevDesc === true) {
      if (this.type == 'D') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(a.ty_demand_revenue) - parseFloat(b.ty_demand_revenue));
      } else if (this.type == 'F') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(a.ty_fulfilled_revenue) - parseFloat(b.ty_fulfilled_revenue));
      }
      this.ordModalUnitsDesc = false;
      this.ordModalUnitsAsc = false;
      this.ordModalRevDesc = false;
      this.ordModalRevAsc = true;
      this.ordModalOrdDesc = false;
      this.ordModalOrdAsc = false;

    }

    else if (e === 'ORD' && this.ordModalOrdDesc === false) {
      if (this.type == 'D') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(b.ty_demand_orders) - parseFloat(a.ty_demand_orders));
      } else if (this.type == 'F') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(b.ty_fulfilled_orders) - parseFloat(a.ty_fulfilled_orders));
      }
      this.ordModalUnitsDesc = false;
      this.ordModalUnitsAsc = false;
      this.ordModalRevDesc = false;
      this.ordModalRevAsc = false;
      this.ordModalOrdDesc = true;
      this.ordModalOrdAsc = false;

    } else if (e === 'ORD' && this.ordModalOrdDesc === true) {
      if (this.type == 'D') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(a.ty_demand_orders) - parseFloat(b.ty_demand_orders));
      } else if (this.type == 'F') {
        this.getSupplierTotalOrderPopup.sort((a, b) => parseFloat(a.ty_fulfilled_orders) - parseFloat(b.ty_fulfilled_orders));
      }
      this.ordModalUnitsDesc = false;
      this.ordModalUnitsAsc = false;
      this.ordModalRevDesc = false;
      this.ordModalRevAsc = false;
      this.ordModalOrdDesc = false;
      this.ordModalOrdAsc = true;
    }
  }

  ratingModal(e) {
    this.openRatingsModal = false;
    this.unitRatModalDesc = true;
    this.unitRatModalAsc = false;
    this.revRatModalDesc = false;
    this.revRatModalAsc = false;
    this.getSupplierTotalOrderPopup = [];
    this.getSupplierRatingPopupLoaded = false;
    let rateBody = {}
    if (this.timePeriod == 'Custom') {
      rateBody = {
        "startDate": this.customDate.startDate,
        "endDate": this.customDate.endDate,
        "rating": e,
        "supplierName": this.supplierName,
        "L4Category": this.selectedL4
      }
    } else {
      rateBody = {
        "timePeriod": this.timePeriod,
        "rating": e,
        "supplierName": this.supplierName,
        "L4Category": this.selectedL4
      }
    }
    if (this.type == 'D') {
      this.supplierService.getSupplierRatingPopup(rateBody).subscribe(
        (data) => {
          this.getSupplierRatingPopup = data['SUPPLIER_RATING_POPUP'];
        },
        (error) => {
          if (error.status == 401) {
            this.logout();
          }
          console.log(error);
        }
      );
    } else if (this.type == 'F') {
      this.supplierService.getSupplierRatingPopupFulfilled(rateBody).subscribe(
        (data) => {
          this.getSupplierRatingPopup = data['SUPPLIER_RATING_POPUP_FULFILLED'];
        },
        (error) => {
          if (error.status == 401) {
            this.logout();
          }
          console.log(error);
        }
      );
    }
    this.getSupplierRatingPopupLoaded = true;
    this.openRatingsModal = true;
  }

  showActiveItemToolTippt() {
    this.showActiveItemToolTip = true
  }
  trends(e) {
    this.trendType = e;
  }

  hideActiveItemToolTip() {
    this.showActiveItemToolTip = false
  }

  feedbackResponse() {
    this.response = {};
    this.body = {
      "sendFrom": this.username,
      "feedback": this.message
    }
    this.supplierService.sendFeedbackMessage(this.body).subscribe((data) => {
      this.response = data;
      if (this.response.feedbackStatus == "sent") {
        this.openFeedbackModal = false;
      } else {
        this.openFeedbackModal = true;
      }
    }, (error) => {
      if (error.status == 401) {
        this.logout();
      }
      console.log(error);
    })
  }

  Change() {
    this.showDigitalModal = !this.showDigitalModal;
    this.getSupplierList = [];
    if (this.showDigitalModal) {
      this.supplierService.getSupplierList().subscribe(
        (data) => {
          this.getSupplierList = data;
        },
        (error) => {
          if (error.status == 401) {
            this.logout();
          }
          console.log(error);
        }
      );
    }
  }

  changeSupplier(e) {
    sessionStorage.setItem("company", e);
    this.supplierName = e;
    this.suppName = this.supplierName;
    this.showDigitalModal = false;
    location.reload();
    this.getData();
  }

  logout() {
    this.supplierService.logout().subscribe(
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
    sessionStorage.setItem("isLoggedIn", 'N');
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  print() {
    window.print();
  }
  changeL4(e) {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((this.timePeriod || this.customDate) && this.type && this.getTimePeriods) {
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = true;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.ARTAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
      this.getData()
    }

  }

  sortCategory(e) {
    this.sort = false;
    if (e === 'REV' && this.REVDesc === true) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_demand_revenue) - parseFloat(b.ty_demand_revenue));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_fulfilled_revenue) - parseFloat(b.ty_fulfilled_revenue));
      }
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = true;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.ARTAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'REV' && this.REVDesc === false) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_demand_revenue) - parseFloat(a.ty_demand_revenue));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_fulfilled_revenue) - parseFloat(a.ty_fulfilled_revenue));
      }
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = true;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'UNIT' && this.UNITDesc === true) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_demand_quantity) - parseFloat(b.ty_demand_quantity));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_fulfilled_quantity) - parseFloat(b.ty_fulfilled_quantity));
      }
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = true;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'UNIT' && this.UNITDesc === false) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_demand_quantity) - parseFloat(a.ty_demand_quantity));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_fulfilled_quantity) - parseFloat(a.ty_fulfilled_quantity));
      }
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = true;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'ORD' && this.ORDDesc === true) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_demand_orders) - parseFloat(b.ty_demand_orders));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_fulfilled_orders) - parseFloat(b.ty_fulfilled_orders));
      }
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = true;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'ORD' && this.ORDDesc === false) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_demand_orders) - parseFloat(a.ty_demand_orders));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_fulfilled_orders) - parseFloat(a.ty_fulfilled_orders));
      }
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = true;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'ART' && this.ARTDesc === true) {
      this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.product_id) - parseFloat(b.product_id));
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDDesc = false;
      this.ARTDesc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.ARTAsc = true;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'ART' && this.ARTDesc === false) {
      this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.product_id) - parseFloat(a.product_id));
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = true;
      this.ORDAsc = false;
      this.ARTDesc = true;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    }
    else if (e === 'PDP' && this.PDPDesc === true) {
      this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_traffic) - parseFloat(b.ty_traffic));
      this.PDPDesc = false;
      this.PDPAsc = true;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    }
    else if (e === 'PDP' && this.PDPDesc === false) {
      this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_traffic) - parseFloat(a.ty_traffic));
      this.PDPDesc = true;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.convDesc = false;
      this.convAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    }
    else if (e === 'CONV' && this.convDesc === true) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_demand_conversion) - parseFloat(b.ty_demand_conversion));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ty_fulfilled_conversion) - parseFloat(b.ty_fulfilled_conversion));
      }
      this.convDesc = false;
      this.convAsc = true;
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'CONV' && this.convDesc === false) {
      if (this.type == 'D') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_demand_conversion) - parseFloat(a.ty_demand_conversion));
      } else if (this.type == 'F') {
        this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ty_fulfilled_conversion) - parseFloat(a.ty_fulfilled_conversion));
      }
      this.convDesc = true;
      this.convAsc = false;
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = false;
    } else if (e === 'RATE' && this.RATEDesc === true) {
      this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(a.ratings) - parseFloat(b.ratings));
      this.convDesc = false;
      this.convAsc = false;
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.RATEDesc = false;
      this.RATEAsc = true;
    } else if (e === 'RATE' && this.RATEDesc === false) {
      this.getSupplierCategoryPerformance.sort((a, b) => parseFloat(b.ratings) - parseFloat(a.ratings));
      this.convDesc = false;
      this.convAsc = false;
      this.PDPDesc = false;
      this.PDPAsc = false;
      this.REVDesc = false;
      this.REVAsc = false;
      this.UNITDesc = false;
      this.UNITAsc = false;
      this.ORDDesc = false;
      this.ORDAsc = false;
      this.ARTDesc = false;
      this.ARTAsc = false;
      this.RATEDesc = true;
      this.RATEAsc = false;
    }
    this.sort = true;
  }

  dropdownOpen() {
    this.openDropdown = true;
  }

  dropdownClose() {
    this.openDropdown = false;
  }

  ratings(e) {
    this.ratingModal(e);
  }

  downloadConversionTraffic() {
    let url = '';
    const amp = '&'
    if (this.supplierName.includes(amp)) {
      this.getSuppname = this.supplierName.replace("&", "%26");
    } else {
      this.getSuppname = this.supplierName
    }
    if (this.timePeriod == 'Custom') {
      url = (this.supplierService.env()).toString() + 'supplier/downloadConversionTraffic' + '?startDate='
        + this.customDate.startDate + "&endDate=" + this.customDate.endDate + '&supplierName=' + this.getSuppname + '&L4Category=' + encodeURIComponent(this.selectedL4);
    } else {
      url = (this.supplierService.env()).toString() + 'supplier/downloadConversionTraffic' + '?timePeriod='
        + this.timePeriod + '&supplierName=' + this.getSuppname + '&L4Category=' + encodeURIComponent(this.selectedL4);
    }
    window.open(url, '_blank');
  }

  downloadProductPerformance() {
    let url = '';
    const amp = '&'
    if (this.supplierName.includes(amp)) {
      this.getSuppname = this.supplierName.replace("&", "%26");
    } else {
      this.getSuppname = this.supplierName
    }
    if (this.timePeriod == 'Custom') {
      url = (this.supplierService.env()).toString() + 'supplier/downloadProductPerformance' + '?startDate='
        + this.customDate.startDate + "&endDate=" + this.customDate.endDate + '&supplierName=' + this.getSuppname;
    } else {
      url = (this.supplierService.env()).toString() + 'supplier/downloadProductPerformance' + '?timePeriod='
        + this.timePeriod + '&supplierName=' + this.getSuppname;
    }
    window.open(url, '_blank');
  }

  downloadProduct() {
    let url = '';
    const amp = '&'
    if (this.supplierName.includes(amp)) {
      this.getSuppname = this.supplierName.replace("&", "%26");
    } else {
      this.getSuppname = this.supplierName
    }
    if (this.timePeriod == 'Custom') {
      url = (this.supplierService.env()).toString() + 'supplier/downloadSupplierArticlePerformance' + '?startDate='
        + this.customDate.startDate + "&endDate=" + this.customDate.endDate + '&supplierName=' + this.getSuppname + '&L4Category=' + encodeURIComponent(this.selectedL4);
    } else {
      url = (this.supplierService.env()).toString() + 'supplier/downloadSupplierArticlePerformance' + '?timePeriod='
        + this.timePeriod + '&supplierName=' + this.getSuppname + '&L4Category=' + encodeURIComponent(this.selectedL4);
    }
    window.open(url, '_blank');
  }

  download() {
    let url = '';
    this.downloadUrl = '';
    const amp = '&'
    if (this.supplierName.includes(amp)) {
      this.getSuppname = this.supplierName.replace("&", "%26");
    } else {
      this.getSuppname = this.supplierName
    }
    if (this.type == 'D') {
      this.downloadUrl = 'supplier/downloadSupplierDemandProductPerf'
    } else if (this.type == 'F') {
      this.downloadUrl = 'supplierFulfilled/downloadSupplierFulfilledProductPerf'
    }
    if (this.timePeriod == 'Custom') {
      url = (this.supplierService.env()).toString() + this.downloadUrl + '?startDate='
        + this.customDate.startDate + "&endDate=" + this.customDate.endDate + '&supplierName=' + this.getSuppname + '&L4Category=' + encodeURIComponent(this.selectedL4);
    } else {
      url = (this.supplierService.env()).toString() + this.downloadUrl + '?timePeriod='
        + this.timePeriod + '&supplierName=' + this.getSuppname + '&L4Category=' + encodeURIComponent(this.selectedL4);
    }
    window.open(url, '_blank');
  }

  getDemandData() {
    this.getSupplierSalesPerformance = [];
    this.getSupplierPenetration = [];
    this.getSupplierRating = [];
    this.getSupplierDigitalChannelStats = [];
    this.getSupplierCategoryPerformance = [];
    this.getSupplierRatingLoaded = false;
    this.getSupplierDigitalChannelStatsEmpty = false;
    this.salesDataLoaded = false;
    this.getSupplierDigitalChannelStatsLoaded = false;
    this.getSupplierCategoryPerformanceLoaded = false;
    this.getSupplierRevenueOrderTrend = [];
    this.getSupplierRevenueOrderTrendLoaded = false;
    this.getSupplierRevenueOrderTrendFulfillLoaded = false;
    this.revchartdata = [];
    this.orderchartdata = [];
    let revarray;
    let ordarray;

    this.supplierService.getSupplierSalesPerformance(this.body).subscribe(
      (data) => {
        this.getSupplierSalesPerformance = data;
        this.salesDataLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierRevenueOrderTrend(this.body).subscribe(
      (data) => {
        this.getSupplierRevenueOrderTrend = data;
        this.revchartdata = [];
        this.orderchartdata = [];
        for (let i = 0; i < this.getSupplierRevenueOrderTrend.length; i++) {
          revarray = [this.getSupplierRevenueOrderTrend[i].fiscal_period, this.getSupplierRevenueOrderTrend[i].ty_demand_revenue, this.getSupplierRevenueOrderTrend[i].ly_demand_revenue]
          ordarray = [this.getSupplierRevenueOrderTrend[i].fiscal_period, this.getSupplierRevenueOrderTrend[i].ty_demand_orders, this.getSupplierRevenueOrderTrend[i].ly_demand_orders]
          this.revchartdata.push(revarray);
          revarray = []
          this.orderchartdata.push(ordarray);
          ordarray = []
        }
        console.log(this.revchartdata)
        this.getSupplierRevenueOrderTrendLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    // this.supplierService.getSupplierPenetration(this.body).subscribe(
    //   (data) => {
    //     this.getSupplierPenetration = data;
    //   },
    //   (error) => {
    //     if(error.status == 401){
    //       this.logout();
    //     }
    //     console.log(error);
    //   }
    // );

    this.supplierService.getSupplierRating(this.body).subscribe(
      (data) => {
        this.getSupplierRating = data;
        this.totalUnitsDemand = 0;
        this.totalProd = 0;
        this.totalReviews = 0;
        this.totalRev = 0;
        for (let i = 0; i < this.getSupplierRating.length; i++) {
          this.totalUnitsDemand = this.totalUnitsDemand + this.getSupplierRating[i].demand_unit;
          this.totalProd = this.totalProd + this.getSupplierRating[i].products;
          this.totalReviews = this.totalReviews + this.getSupplierRating[i].review;
          this.totalRev = this.totalRev + this.getSupplierRating[i].demand_revenue
        }
        this.getSupplierRatingLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierDigitalChannelStats(this.body).subscribe(
      (data) => {
        this.getSupplierDigitalChannelStats = data;
        if (this.getSupplierDigitalChannelStats.length == 0) {
          this.getSupplierDigitalChannelStatsEmpty = true;
        } else {
          this.getSupplierDigitalChannelStatsEmpty = false;
        }

        this.getSupplierDigitalChannelStatsLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierCategoryPerformance(this.body).subscribe(
      (data) => {
        this.getSupplierCategoryPerformance = data;
        this.getSupplierCategoryPerformanceLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

  }

  getFulfillData() {
    this.getSupplierSalesPerformance = [];
    this.getSupplierPenetration = [];
    this.getSupplierRating = [];
    this.getSupplierDigitalChannelStats = [];
    this.getSupplierCategoryPerformance = [];
    this.getSupplierRatingLoaded = false;
    this.salesDataLoaded = false;
    this.getSupplierDigitalChannelStatsLoaded = false;
    this.getSupplierCategoryPerformanceLoaded = false;
    this.getSupplierRevenueOrderTrendFulfill = [];
    this.getSupplierRevenueOrderTrendFulfillLoaded = false;
    this.getSupplierRevenueOrderTrendLoaded = false;
    this.revchartdata = [];
    this.orderchartdata = [];
    let revarray;
    let ordarray;
    this.supplierService.getSupplierRevenueOrderTrendFulfill(this.body).subscribe(
      (data) => {
        this.getSupplierRevenueOrderTrendFulfill = data;
        this.revchartdata = [];
        this.orderchartdata = [];
        for (let i = 0; i < this.getSupplierRevenueOrderTrendFulfill.length; i++) {
          revarray = [this.getSupplierRevenueOrderTrendFulfill[i].fiscal_period, this.getSupplierRevenueOrderTrendFulfill[i].ty_fulfilled_revenue, this.getSupplierRevenueOrderTrendFulfill[i].ly_fulfilled_revenue]
          ordarray = [this.getSupplierRevenueOrderTrendFulfill[i].fiscal_period, this.getSupplierRevenueOrderTrendFulfill[i].ty_fulfilled_orders, this.getSupplierRevenueOrderTrendFulfill[i].ly_fulfilled_orders]
          this.revchartdata.push(revarray);
          revarray = [];
          this.orderchartdata.push(ordarray);
          ordarray = [];
        }
        this.getSupplierRevenueOrderTrendFulfillLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );
    this.supplierService.getSupplierSalesPerformanceFulfilled(this.body).subscribe(
      (data) => {
        this.getSupplierSalesPerformance = data['SUPPLIER_SALES_PERFORMANCE_FULFILLED'];
        this.salesDataLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierPenetrationFulfilled(this.body).subscribe(
      (data) => {
        this.getSupplierPenetration = data;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierRatingFulfilled(this.body).subscribe(
      (data) => {
        this.getSupplierRating = data['SUPPLIER_RATING_FULFILLED'];
        this.totalUnitsDemand = 0;
        this.totalProd = 0;
        this.totalReviews = 0;
        this.totalRev = 0;
        for (let i = 0; i < this.getSupplierRating.length; i++) {
          this.totalUnitsDemand = this.totalUnitsDemand + this.getSupplierRating[i].fulfilled_unit;
          this.totalProd = this.totalProd + this.getSupplierRating[i].products;
          this.totalReviews = this.totalReviews + this.getSupplierRating[i].review;
          this.totalRev = this.totalRev + this.getSupplierRating[i].fulfilled_revenue
        }
        this.getSupplierRatingLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierDigitalChannelStatsFulfilled(this.body).subscribe(
      (data) => {
        this.getSupplierDigitalChannelStats = data['SUPPLIER_DIGITAL_STATS_FULFILLED'];
        this.getSupplierDigitalChannelStatsLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierCategoryPerformanceFulfilled(this.body).subscribe(
      (data) => {
        this.getSupplierCategoryPerformance = data['SUPPLIER_PRODUCT_PERFORMANCE_FULFILLED'];
        this.getSupplierCategoryPerformanceLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

  }

  getData() {
    this.getSupplierDigitalChannelStatsItemTotal = [];
    this.getConversionTraffic = [];
    this.getSupplierMembers = [];
    this.getSupplierFunnel = [];
    this.getSupplierFunnelTotal = 0;
    this.pdpWidth = 0;
    this.atcWidth = 0;
    this.orderWidth = 0;
    this.getLevel4Mapping = [];

    this.total_articles = 0;
    this.active_articles = 0;
    this.bopic_articles = 0;
    this.sdd_articles = 0;

    this.getConversionTrafficEmpty = false;
    this.getConversionTrafficLoaded = false;
    this.getSupplierMembersLoaded = false;
    this.L4Loaded = false;

    this.body = {};
    if (this.timePeriod == 'Custom') {
      this.body = {
        "startDate": this.customDate.startDate,
        "endDate": this.customDate.endDate,
        "supplierName": this.supplierName,
        "L4Category": this.selectedL4
      }
    } else {
      this.body = {
        "timePeriod": this.timePeriod,
        "supplierName": this.supplierName,
        "L4Category": this.selectedL4
      }
    }

    if (this.timePeriod == 'Custom') {
      this.activityURL = '?startDate=' + this.customDate.startDate + '&endDate=' + this.customDate.endDate +
        "&supplierName=" + this.supplierName + "&L4Category=" + this.selectedL4
    } else {
      this.activityURL = '?timePeriod=' + this.timePeriod +
        "&supplierName=" + this.supplierName + "&L4Category=" + this.selectedL4
    }


    if (this.type == 'D') {
      this.supplierService.activity(this.activityURL).subscribe((data) => {

      })
    } else if (this.type == 'F') {
      this.supplierService.activityf(this.activityURL).subscribe((data) => {

      })
    }

    if (this.type == 'D') {
      this.getDemandData();
    } else if (this.type == 'F') {
      this.getFulfillData();
    }

    this.supplierService.getLevel4Mapping(this.body).subscribe((data) => {
      this.getLevel4Mapping = data;
      this.L4Loaded = true;
    })

    this.supplierService.getSupplierDigitalChannelStatsItemTotal(this.body).subscribe(
      (data) => {
        this.getSupplierDigitalChannelStatsItemTotal = data;
        this.total_articles = this.getSupplierDigitalChannelStatsItemTotal[0].total_articles;
        this.active_articles = this.getSupplierDigitalChannelStatsItemTotal[0].active_articles;
        this.bopic_articles = this.getSupplierDigitalChannelStatsItemTotal[0].bopic_articles;
        this.sdd_articles = this.getSupplierDigitalChannelStatsItemTotal[0].sdd_articles;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getConversionTraffic(this.body).subscribe(
      (data) => {
        this.getConversionTraffic = data;
        if (this.getConversionTraffic.length == 0) {
          this.getConversionTrafficEmpty = true;
        } else {
          this.getConversionTrafficEmpty = false;
        }
        this.getConversionTrafficLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

    this.supplierService.getSupplierMembers(this.body).subscribe(
      (data) => {
        this.getSupplierMembers = data;
        this.getSupplierMembersLoaded = true;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );
    this.supplierService.getSupplierFunnel(this.body).subscribe(
      (data) => {
        this.getSupplierFunnel = data;
        this.getSupplierFunnelTotal = this.getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].pdp_view +
          this.getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].add_to_cart + this.getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].orders;
        this.pdpWidth = (this.getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].pdp_view / this.getSupplierFunnelTotal) * 100;
        this.atcWidth = (this.getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].add_to_cart / this.getSupplierFunnelTotal) * 100;;
        this.orderWidth = (this.getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].orders / this.getSupplierFunnelTotal) * 100;;
      },
      (error) => {
        if (error.status == 401) {
          this.logout();
        }
        console.log(error);
      }
    );

  }
}
