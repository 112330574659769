<div class="row rightmargin">
   <div class="col-8">
      <p class="supplierName cursor" (click)="Change()" *ngIf="change_supplier == 'Y'">
         {{ suppName }}
         <span class='change noPrint'>
            <fa-icon [icon]="faCaretDown"></fa-icon>
         </span>
      </p>
      <p class="supplierName" *ngIf="change_supplier == 'N'">
         {{ suppName }}
      </p>
   </div>
   <div class="col-2 noPrint mt-2">
      <select class="select-css cursor" (change)="changeL4($event.target.value)" [(ngModel)]="selectedL4"
         *ngIf="L4Loaded">
         <option value="" class="cursor dropdown-item disabled">Select L4 Category</option>
         <option class="cursor dropdown-item" *ngFor="let summary of getLevel4Mapping" value={{summary.level_4}}>
            {{summary.level_4}}
         </option>
      </select>
   </div>
   <div class="col-2 noPrint">
      <!-- Content here -->
      <ul class="nav nav-pills" (mouseleave)='dropdownClose()'>
         <li class="nav-item dropdown">
            <p class="nav-link dropdown-toggle name" role="button" aria-haspopup="true" aria-expanded="false"
               (click)='dropdownOpen()'>Hi, {{ username }}</p>
            <div class="dropdown-menu dropdown-menu-right" [ngClass]="{'showDropdown': openDropdown}"
               (mouseover)='dropdownOpen()'>
               <a class="dropdown-item" href="../../assets/handbook/gameONhelp.docx">
                  <fa-icon [icon]="faQuestionCircle"></fa-icon>
                  Help
               </a>
               <p class="dropdown-item" (click)="print()">
                  <fa-icon [icon]="faPrint"></fa-icon>
                  Print
               </p>
               <p class="dropdown-item mb-0" (click)="feedback()">
                  <fa-icon [icon]="faCommentDots"></fa-icon>
                  Feedback
               </p>
               <hr class="lines">
               <p class="dropdown-item sign" (click)="logout()">
                  <fa-icon [icon]="faSignOutAlt"></fa-icon>
                  Sign Out
               </p>
            </div>
         </li>
      </ul>
   </div>
   <div class="col-12">
      <hr class="blackLines">
   </div>
   <div class="col-12">
      <span class="Undertext left" *ngIf="timePeriod == 'YESTERDAY'">
         Data for {{getTimePeriods[0]?.yesterday | date}}
      </span>
      <span class="Undertext left" *ngIf="timePeriod == 'WTD'">
         Data from {{getTimePeriods[0]?.ty_wtd_start_date | date}}
         to {{getTimePeriods[0]?.ty_wtd_end_date | date}}
      </span>
      <span class="Undertext left" *ngIf="timePeriod == 'MTD'">
         Data from {{getTimePeriods[0]?.ty_mtd_start_date | date}}
         to {{getTimePeriods[0]?.ty_mtd_end_date | date}}
      </span>
      <span class="Undertext left" *ngIf="timePeriod == 'QTD'">
         Data from {{getTimePeriods[0]?.ty_qtd_start_date | date}}
         to {{getTimePeriods[0]?.ty_qtd_end_date | date}}
      </span>
      <span class="Undertext left" *ngIf="timePeriod == 'YTD'">
         Data from {{getTimePeriods[0]?.ty_ytd_start_date | date}}
         to {{getTimePeriods[0]?.ty_ytd_end_date | date}}
      </span>
      <span class="Undertext left" *ngIf="timePeriod == 'Custom'">
         Data from {{ customDate.startDate | date}}
         to {{ customDate.endDate | date}}
      </span>
   </div>
   <div class="col-12">
      <hr class="blackLines">
   </div>
   <!-- Data Refresh -->
   <div class="col-12 noPrint" *ngIf="datarefresh">
      <div class="alert alert-danger" role="alert">
         <p class="alertText">
            Data refresh is in progress. Data visible may not reflect most recent updates at this time.
            Please refresh after some time.
         </p>
      </div>
   </div>
   <!-- Sales Performance -->
   <div class="col-12">
      <p class="widgetHeading">
         Sales Performance
      </p>
   </div>
   <!-- Revenue -->
   <div class="containerCard" [ngClass]="{'containerCardDemand' : (type == 'D')}">
      <div class="card">
         <div class="card__content">
            <h1 class="card__title">Revenue</h1>
            <div class="row" *ngIf="salesDataLoaded">
               <div class="col-6 p-0 p-0">
                  <p class="cardValue"
                     *ngIf="type == 'D' && (getSupplierSalesPerformance[0].ty_demand_revenue || getSupplierSalesPerformance[0].ty_demand_revenue == 0)">
                     ${{getSupplierSalesPerformance[0]?.ty_demand_revenue | number: '.0-0'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'D' && (!getSupplierSalesPerformance[0].ty_demand_revenue && getSupplierSalesPerformance[0].ty_demand_revenue != 0)">
                     $0
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (getSupplierSalesPerformance[0].ty_fulfilled_revenue || getSupplierSalesPerformance[0].ty_fulfilled_revenue == 0)">
                     ${{getSupplierSalesPerformance[0]?.ty_fulfilled_revenue | number: '.0-0'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (!getSupplierSalesPerformance[0].ty_fulfilled_revenue && getSupplierSalesPerformance[0].ty_fulfilled_revenue != 0)">
                     $0
                  </p>
                  <p class="cardText">
                     {{ timePeriod }}
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'D'">
                  <p class="cardValue"
                     *ngIf="getSupplierSalesPerformance[0].vs_ly_demand_revenue || getSupplierSalesPerformance[0].vs_ly_demand_revenue == 0"
                     [ngStyle]="{'color': (getSupplierSalesPerformance[0].vs_ly_demand_revenue >= 0) ? '#3BE05F' :
               '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ly_demand_revenue | number: '.0-2'}}%
                  </p>
                  <p class="cardValue"
                     *ngIf="!getSupplierSalesPerformance[0].vs_ly_demand_revenue && getSupplierSalesPerformance[0].vs_ly_demand_revenue != 0">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'F'">
                  <p class="cardValue"
                     *ngIf="getSupplierSalesPerformance[0].vs_ly_fulfilled_revenue || getSupplierSalesPerformance[0].vs_ly_fulfilled_revenue == 0"
                     [ngStyle]="{'color': (getSupplierSalesPerformance[0].vs_ly_fulfilled_revenue >= 0) ? '#3BE05F' :
               '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ly_fulfilled_revenue | number: '.0-2'}}%
                  </p>
                  <p class="cardValue"
                     *ngIf="!getSupplierSalesPerformance[0].vs_ly_fulfilled_revenue && getSupplierSalesPerformance[0].vs_ly_fulfilled_revenue != 0">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
            </div>
            <div class="row" *ngIf="!salesDataLoaded">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- AOV -->
   <div class="containerCard" [ngClass]="{'containerCardDemand' : (type == 'D')}">
      <div class="card">
         <div class="card__content">
            <h1 class="card__title">AOV</h1>
            <div class="row" *ngIf="salesDataLoaded">
               <div class="col-6 p-0">
                  <p class="cardValue"
                     *ngIf="type == 'D' && (getSupplierSalesPerformance[0].ty_demand_aov || getSupplierSalesPerformance[0].ty_demand_aov == 0)">
                     ${{getSupplierSalesPerformance[0]?.ty_demand_aov | number: '.0-0'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'D' && (!getSupplierSalesPerformance[0].ty_demand_aov && getSupplierSalesPerformance[0].ty_demand_aov != 0)">
                     $0
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F'&& (getSupplierSalesPerformance[0].ty_fulfilled_aov || getSupplierSalesPerformance[0].ty_fulfilled_aov == 0)">
                     ${{getSupplierSalesPerformance[0]?.ty_fulfilled_aov | number: '.0-0'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (!getSupplierSalesPerformance[0].ty_fulfilled_aov && getSupplierSalesPerformance[0].ty_fulfilled_aov != 0)">
                     $0
                  </p>
                  <p class="cardText">
                     {{ timePeriod }}
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'D'">
                  <p class="cardValue" *ngIf="
               (getSupplierSalesPerformance[0].vs_ly_demand_aov_pct || 
               getSupplierSalesPerformance[0].vs_ly_demand_aov_pct == 0)" [ngStyle]="{'color': 
               (getSupplierSalesPerformance[0].vs_ly_demand_aov_pct >= 0) ? '#3BE05F' : '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ly_demand_aov_pct | number: '.0-2'}}%
                  </p>
                  <p class="cardValue" *ngIf="(!getSupplierSalesPerformance[0].vs_ly_demand_aov_pct && 
                  getSupplierSalesPerformance[0].vs_ly_demand_aov_pct != 0)">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'F'">
                  <p class="cardValue" *ngIf="
               (getSupplierSalesPerformance[0].vs_ly_fulfilled_aov_pct || 
               getSupplierSalesPerformance[0].vs_ly_fulfilled_aov_pct == 0)" [ngStyle]="{'color': 
               (getSupplierSalesPerformance[0].vs_ly_fulfilled_aov_pct >= 0) ? '#3BE05F' : '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ly_fulfilled_aov_pct | number: '.0-2'}}%
                  </p>
                  <p class="cardValue" *ngIf="(!getSupplierSalesPerformance[0].vs_ly_fulfilled_aov_pct && 
                  getSupplierSalesPerformance[0].vs_ly_fulfilled_aov_pct != 0)">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
            </div>
            <div class="row" *ngIf="!salesDataLoaded">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- Basket Size -->
   <div class="containerCard" [ngClass]="{'containerCardDemand' : (type == 'D')}">
      <div class="card">
         <div class="card__content">
            <h1 class="card__title">Basket Size</h1>
            <div class="row" *ngIf="salesDataLoaded">
               <div class="col-6 p-0">
                  <p class="cardValue"
                     *ngIf="type == 'D' && (getSupplierSalesPerformance[0].ty_demand_basket_size || getSupplierSalesPerformance[0].ty_demand_basket_size == 0)">
                     {{getSupplierSalesPerformance[0]?.ty_demand_basket_size | number: '.0-2'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'D' && (!getSupplierSalesPerformance[0].ty_demand_basket_size && getSupplierSalesPerformance[0].ty_demand_basket_size != 0)">
                     0
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (getSupplierSalesPerformance[0].ty_fulfilled_basket_size || getSupplierSalesPerformance[0].ty_fulfilled_basket_size == 0)">
                     {{getSupplierSalesPerformance[0]?.ty_fulfilled_basket_size | number: '.0-2'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (!getSupplierSalesPerformance[0].ty_fulfilled_basket_size && getSupplierSalesPerformance[0].ty_fulfilled_basket_size != 0)">
                     0
                  </p>
                  <p class="cardText">
                     {{ timePeriod }}
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'D'">
                  <p class="cardValue" *ngIf="
               (getSupplierSalesPerformance[0].vs_ty_demand_basket_size_pct || 
               getSupplierSalesPerformance[0].vs_ty_demand_basket_size_pct == 0)" [ngStyle]="{'color': 
               (getSupplierSalesPerformance[0].vs_ty_demand_basket_size_pct >= 0) ? '#3BE05F' : '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ty_demand_basket_size_pct | number: '.0-2'}}%
                  </p>
                  <p class="cardValue" *ngIf="(!getSupplierSalesPerformance[0].vs_ty_demand_basket_size_pct 
                  && getSupplierSalesPerformance[0].vs_ty_demand_basket_size_pct != 0)">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'F'">
                  <p class="cardValue" *ngIf="
               (getSupplierSalesPerformance[0].vs_ty_fulfilled_basket_size_pct || 
               getSupplierSalesPerformance[0].vs_ty_fulfilled_basket_size_pct == 0)" [ngStyle]="{'color': 
               (getSupplierSalesPerformance[0].vs_ty_fulfilled_basket_size_pct >= 0) ? '#3BE05F' : '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ty_fulfilled_basket_size_pct | number: '.0-2'}}%
                  </p>
                  <p class="cardValue" *ngIf="(!getSupplierSalesPerformance[0].vs_ty_fulfilled_basket_size_pct 
                  && getSupplierSalesPerformance[0].vs_ty_fulfilled_basket_size_pct != 0)">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
            </div>
            <div class="row" *ngIf="!salesDataLoaded">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- Orders -->
   <div class="containerCard" [ngClass]="{'containerCardDemand' : (type == 'D')}">
      <div class="card">
         <div class="card__content">
            <h1 class="card__title">Orders</h1>
            <div class="row" *ngIf="salesDataLoaded">
               <div class="col-6 p-0">
                  <p class="cardValue"
                     *ngIf="type == 'D' && (getSupplierSalesPerformance[0].ty_demand_orders || getSupplierSalesPerformance[0].ty_demand_orders == 0)">
                     {{getSupplierSalesPerformance[0]?.ty_demand_orders | number: '.0-2'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'D' && (!getSupplierSalesPerformance[0].ty_demand_orders && getSupplierSalesPerformance[0].ty_demand_orders != 0)">
                     0
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (getSupplierSalesPerformance[0].ty_fulfilled_orders || getSupplierSalesPerformance[0].ty_fulfilled_orders == 0)">
                     {{getSupplierSalesPerformance[0]?.ty_fulfilled_orders | number: '.0-2'}}
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (!getSupplierSalesPerformance[0].ty_fulfilled_orders && getSupplierSalesPerformance[0].ty_fulfilled_orders != 0)">
                     0
                  </p>
                  <p class="cardText">
                     {{ timePeriod }}
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'D'">
                  <p class="cardValue" *ngIf="(getSupplierSalesPerformance[0].vs_ly_demand_orders || 
               getSupplierSalesPerformance[0].vs_ly_demand_orders == 0)" [ngStyle]="{'color': 
               (getSupplierSalesPerformance[0].vs_ly_demand_orders >= 0) ? '#3BE05F' : '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ly_demand_orders | number: '.0-2'}}%
                  </p>
                  <p class="cardValue" *ngIf="(!getSupplierSalesPerformance[0].vs_ly_demand_orders && 
                  getSupplierSalesPerformance[0].vs_ly_demand_orders != 0)">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
               <div class="col-6 p-0" *ngIf="type == 'F'">
                  <p class="cardValue" *ngIf="(getSupplierSalesPerformance[0].vs_ly_fulfilled_orders || 
               getSupplierSalesPerformance[0].vs_ly_fulfilled_orders == 0)" [ngStyle]="{'color': 
               (getSupplierSalesPerformance[0].vs_ly_fulfilled_orders >= 0) ? '#3BE05F' : '#ff5c5d'}">
                     {{getSupplierSalesPerformance[0]?.vs_ly_fulfilled_orders | number: '.0-2'}}%
                  </p>
                  <p class="cardValue" *ngIf="(!getSupplierSalesPerformance[0].vs_ly_fulfilled_orders && 
                  getSupplierSalesPerformance[0].vs_ly_fulfilled_orders != 0)">
                     NA
                  </p>
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
            </div>
            <div class="row" *ngIf="!salesDataLoaded">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- Penetration -->
   <div class="containerCard" *ngIf="type == 'F'">
      <div class="card">
         <div class="card__content">
            <h1 class="card__title">Penetration</h1>
            <div class="row" *ngIf="salesDataLoaded">
               <div class="col-12">
                  <p class="cardValue"
                     *ngIf="type == 'D' && (getSupplierPenetration?.SUPPLIER_PENETRATION[0]?.supplier_pen_d || getSupplierPenetration?.SUPPLIER_PENETRATION[0]?.supplier_pen_d == 0)">
                     {{getSupplierPenetration?.SUPPLIER_PENETRATION[0]?.supplier_pen_d | number: '.0-2'}}%
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'D' && (!getSupplierPenetration?.SUPPLIER_PENETRATION[0]?.supplier_pen_d && getSupplierPenetration?.SUPPLIER_PENETRATION[0]?.supplier_pen_d != 0)">
                     0%
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (getSupplierPenetration?.SUPPLIER_PENETRATION_FULFILLED[0]?.supplier_pen_f || getSupplierPenetration?.SUPPLIER_PENETRATION_FULFILLED[0]?.supplier_pen_f == 0)">
                     {{getSupplierPenetration?.SUPPLIER_PENETRATION_FULFILLED[0]?.supplier_pen_f | number: '.0-2'}}%
                  </p>
                  <p class="cardValue"
                     *ngIf="type == 'F' && (!getSupplierPenetration?.SUPPLIER_PENETRATION_FULFILLED[0]?.supplier_pen_f && getSupplierPenetration?.SUPPLIER_PENETRATION_FULFILLED[0]?.supplier_pen_f != 0)">
                     0%
                  </p>
                  <p class="cardText">
                     {{ timePeriod }}
                  </p>
               </div>
            </div>
            <div class="row" *ngIf="!salesDataLoaded">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-12" *ngIf="timePeriod != 'YESTERDAY'">
      <p class="widgetHeading">
         Trend Performance
         <span class="btn-group w-25 mt-2 right" role="group" aria-label="Trend togle">
            <button type="button" class="btn borderBtn" [ngClass]="{'btnactive': trendType === 'REV'}"
               (click)="trends('REV')">Revenue</button>
            <button type="button" class="btn borderBtn" [ngClass]="{'btnactive': trendType === 'ORD'}"
               (click)="trends('ORD')">Orders</button>
         </span>
      </p>

   </div>
   <div class="containerCardReview" *ngIf="timePeriod != 'YESTERDAY'">
      <div class="card reviewCard">
         <div class="card__content"
            *ngIf="getSupplierRevenueOrderTrendLoaded || getSupplierRevenueOrderTrendFulfillLoaded">
            <div class="row">
               <div class="col-12" *ngIf="trendType === 'REV'">
                  <h1 class="card__title mb-1">Revenue Trend
                     <span class="ml-2 right">
                        <fa-icon class="red" [icon]="faSquareFull"></fa-icon>
                        Last Year
                     </span>
                     <span class="right ml-2">
                        <fa-icon class="green" [icon]="faSquareFull"></fa-icon>
                        This Year
                     </span>
                  </h1>
                  <google-chart #chart [title]="revtitle" [type]="charttype" [data]="revchartdata"
                     [columns]="revcolumnNames" [options]="options" [width]="width" [height]="height">
                  </google-chart>
               </div>
               <div class="col-12" *ngIf="trendType === 'ORD'">
                  <h1 class="card__title mb-1 mt-2">Orders Trend</h1>
                  <google-chart #chart [title]="ordertitle" [type]="charttype" [data]="orderchartdata"
                     [columns]="ordercolumnNames" [options]="orderoptions" [width]="width" [height]="height">
                  </google-chart>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- Ratings and Reviews -->
   <div class="col-12">
      <p class="widgetHeading">
         Rating and Reviews (Active Items)
         <span (mouseover)="showActiveItemToolTippt()" (mouseout)="hideActiveItemToolTip()">
            <fa-icon [icon]="faInfoCircle" class="cursor"></fa-icon>
         </span>
         <span class='right' *ngIf='showActiveItemToolTip'>
            **Ratings and Reviews not available for products exclusive to SDD
         </span>
      </p>
   </div>
   <div class="containerCardReview">
      <div class="card reviewCard" *ngIf="getSupplierRating.length > 0">
         <div class="card__content" *ngIf="getSupplierRatingLoaded">
            <div class="row">
               <div class="col-4"></div>
               <div class="col-8">
                  <div class="row">
                     <div class="col-2">
                        <p class="cardText">
                           # of Articles
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardText">
                           Revenue
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardText">
                           vs LY
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardText">
                           Revenue%
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardText">
                           Units sold
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardText">
                           # of Reviews
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row" *ngFor="let rate of getSupplierRating">
               <div class="col-4">
                  <div class="star cursor" *ngIf="rate.ratings == '5'" (click)="ratings('5')">
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                  </div>
                  <div class="star cursor" *ngIf="rate.ratings == '4'" (click)="ratings('4')">
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                  </div>
                  <div class="star cursor" *ngIf="rate.ratings == '3'" (click)="ratings('3')">
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                  </div>
                  <div class="star cursor" *ngIf="rate.ratings == '2'" (click)="ratings('2')">
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                  </div>
                  <div class="star cursor" *ngIf="rate.ratings == '1'" (click)="ratings('1')">
                     <fa-icon class="gold" [icon]="faStar"></fa-icon>
                  </div>
                  <div class="star cursor" *ngIf="rate.ratings == '0'" (click)="ratings('0')">
                     <fa-icon class="gold" [icon]="farStar"></fa-icon>
                  </div>
               </div>
               <div class="col-8">
                  <div class="row">
                     <div class="col-2">
                        <p class="cardValue cursor" (click)="ratings(rate.ratings)">
                           {{rate.products | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue" *ngIf="type =='D'">
                           ${{rate.demand_revenue | number: '.0-0'}}
                        </p>
                        <p class="cardValue" *ngIf="type =='F'">
                           ${{rate.fulfilled_revenue | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue" *ngIf="(rate.vs_ly_revenue != '-999999')"
                           [ngStyle]="{'color': (rate.vs_ly_revenue >= 0) ? '#3BE05F' : '#ff5c5d'}">
                           {{rate.vs_ly_revenue | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="(rate.vs_ly_revenue == '-999999')">
                           NA
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue" *ngIf="type =='D' && rate.demand_revenue_percent != '-999999'">
                           {{rate.demand_revenue_percent | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="type =='F' && rate.fulfilled_revenue_percent != '-999999'">
                           {{rate.fulfilled_revenue_percent | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="type =='D' && rate.demand_revenue_percent == '-999999'">
                           NA
                        </p>
                        <p class="cardValue" *ngIf="type =='F' && rate.fulfilled_revenue_percent == '-999999'">
                           NA
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue" *ngIf="type =='D'">
                           {{rate.demand_unit | number: '.0-0'}}
                        </p>
                        <p class="cardValue" *ngIf="type =='F'">
                           {{rate.fulfilled_unit | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue cursor">
                           {{rate.review | number: '.0-2'}}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-12">
                  <hr class="lines">
               </div>
               <div class="col-4">
                  <p class="norate">
                     Total
                  </p>
               </div>
               <div class="col-8">
                  <div class="row">
                     <div class="col-2">
                        <p class="cardValue">
                           {{totalProd | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue">
                           ${{totalRev | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue">
                        </p>
                     </div>
                     <div class="col-2">
                     </div>
                     <div class="col-2">
                        <p class="cardValue">
                           {{totalUnitsDemand | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-2">
                        <p class="cardValue cursor">
                           {{totalReviews | number: '.0-0'}}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="card__content" *ngIf="!getSupplierRatingLoaded">
            <div class="row">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
      <div class="card reviewCard" *ngIf="getSupplierRating.length == 0">
         <div class="card__content" *ngIf="getSupplierRatingLoaded">
            <p class="widgetHeading text-center my-3">
               Data not available
            </p>
         </div>
      </div>
   </div>
   <!-- Digital Channel Stats -->
   <div class="col-12">
      <p class="widgetHeading">
         Digital Channel Stats
      </p>
   </div>
   <div class="containerCardReview">
      <div class="card reviewCard">
         <div class="card__content"
            *ngIf="getSupplierDigitalChannelStatsLoaded && !getSupplierDigitalChannelStatsEmpty">
            <div class="row">
               <div class="col-4">
                  <div class="row">
                     <div class="col-6">
                        <p class="cardText">
                        </p>
                     </div>
                     <div class="col-3">
                        <p class="cardText">
                           Items available
                        </p>
                     </div>
                     <div class="col-3">
                        <p class="cardText">
                           Items sold
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-4">
                  <div class="row">
                     <div class="col-4">
                        <p class="cardText">
                           Revenue
                        </p>
                     </div>
                     <div class="col-4">
                        <p class="cardText">
                           vs LY
                        </p>
                     </div>
                     <div class="col-4">
                        <p class="cardText">
                           Total Revenue %
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-4">
                  <div class="row">
                     <div class="col-4">
                        <p class="cardText">
                           Orders
                        </p>
                     </div>
                     <div class="col-4">
                        <p class="cardText">
                           vs LY
                        </p>
                     </div>
                     <div class="col-4">
                        <p class="cardText">
                           Total Order %
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row space" *ngFor="let digi of getSupplierDigitalChannelStats">
               <div class="col-4">
                  <div class="row">
                     <div class="col-6">
                        <p class="cardValue text-left">
                           {{digi.digital_channel}}
                        </p>
                     </div>
                     <div class="col-3">
                        <p class="cardValue" *ngIf="digi.digital_channel == 'BOPIC'">
                           {{bopic_articles}}
                        </p>
                        <p class="cardValue" *ngIf="digi.digital_channel == 'Same Day Delivery'">
                           {{sdd_articles}}
                        </p>
                        <p class="cardValue" *ngIf="digi.digital_channel == 'Ship To Home'">
                           {{active_articles}}
                        </p>
                     </div>
                     <div class="col-3">
                        <p class="cardValue" *ngIf="type == 'D'">
                           {{digi.demand_items_sold}}
                        </p>
                        <p class="cardValue" *ngIf="type == 'F'">
                           {{digi.fulfilled_items_sold}}
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-4">
                  <div class="row">
                     <div class="col-4">
                        <p class="cardValue" *ngIf="type == 'D'">
                           ${{digi.demand_revenue | number: '.0-0'}}
                        </p>
                        <p class="cardValue" *ngIf="type == 'F'">
                           ${{digi.fulfilled_revenue | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-4" *ngIf="type == 'D'">
                        <p class="cardValue" *ngIf="(digi.vs_ly_demand_revenue || digi.vs_ly_demand_revenue == 0)"
                           [ngStyle]="{'color': (digi.vs_ly_demand_revenue >= 0) ? '#3BE05F' : '#ff5c5d'}">
                           {{digi.vs_ly_demand_revenue | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="(!digi.vs_ly_demand_revenue && digi.vs_ly_demand_revenue != 0)">
                           NA
                        </p>
                     </div>
                     <div class="col-4" *ngIf="type == 'F'">
                        <p class="cardValue" *ngIf="(digi.vs_ly_fulfilled_revenue || digi.vs_ly_fulfilled_revenue == 0)"
                           [ngStyle]="{'color': (digi.vs_ly_fulfilled_revenue >= 0) ? '#3BE05F' : '#ff5c5d'}">
                           {{digi.vs_ly_fulfilled_revenue | number: '.0-2'}}%
                        </p>
                        <p class="cardValue"
                           *ngIf="(!digi.vs_ly_fulfilled_revenue && digi.vs_ly_fulfilled_revenue != 0)">
                           NA
                        </p>
                     </div>
                     <div class="col-4">
                        <p class="cardValue" *ngIf="type == 'D'">
                           {{digi.demand_revenue_percent | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="type == 'F'">
                           {{digi.fulfilled_revenue_percent | number: '.0-2'}}%
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-4">
                  <div class="row">
                     <div class="col-4">
                        <p class="cardValue" *ngIf="type == 'D'">
                           {{digi.demand_orders | number: '.0-0'}}
                        </p>
                        <p class="cardValue" *ngIf="type == 'F'">
                           {{digi.fulfilled_orders | number: '.0-0'}}
                        </p>
                     </div>
                     <div class="col-4" *ngIf="type == 'D'">
                        <p class="cardValue" *ngIf="(digi.vs_ly_demand_orders || digi.vs_ly_demand_orders == 0)"
                           [ngStyle]="{'color': (digi.vs_ly_demand_orders >= 0) ? '#3BE05F' : '#ff5c5d'}">
                           {{digi.vs_ly_demand_orders | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="(!digi.vs_ly_demand_orders && digi.vs_ly_demand_orders != 0)">
                           NA
                        </p>
                     </div>
                     <div class="col-4" *ngIf="type == 'F'">
                        <p class="cardValue" *ngIf="(digi.vs_ly_fulfilled_orders || digi.vs_ly_fulfilled_orders == 0)"
                           [ngStyle]="{'color': (digi.vs_ly_fulfilled_orders >= 0) ? '#3BE05F' : '#ff5c5d'}">
                           {{digi.vs_ly_fulfilled_orders | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="(!digi.vs_ly_fulfilled_orders && digi.vs_ly_fulfilled_orders != 0)">
                           NA
                        </p>
                     </div>
                     <div class="col-4">
                        <p class="cardValue" *ngIf="type == 'D'">
                           {{digi.demand_orders_percent | number: '.0-2'}}%
                        </p>
                        <p class="cardValue" *ngIf="type == 'F'">
                           {{digi.fulfilled_orders_percent | number: '.0-2'}}%
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-12">
                  <hr class="lines">
               </div>
            </div>
         </div>
         <div class="card__content" *ngIf="getSupplierDigitalChannelStatsLoaded && getSupplierDigitalChannelStatsEmpty">
            <p class="widgetHeading text-center my-3">
               Data not available
            </p>
         </div>
         <div class="card__content" *ngIf="!getSupplierDigitalChannelStatsLoaded">
            <div class="loader"> </div>
            <p class="cardText text-center mb-3">
               Loading
            </p>
         </div>
      </div>
   </div>
   <!-- Product Performance -->
   <div class="col-12">
      <p class="widgetHeading">
         Product Performance
         <span class="right cursor" (click)="download()">
            <fa-icon [icon]="faDownload" class="pl-4"></fa-icon>
            Download
         </span>
         <span class="right cursor" (click)="downloadProduct()" *ngIf="type == 'D'">
            <fa-icon [icon]="faDownload" class="pl-4"></fa-icon>
            Product Performance vs Last Year
         </span>
         <span class="right cursor" (click)="downloadProductPerformance()"
            *ngIf="type == 'D' && selectedL4 == '' && timePeriod == 'Custom'">
            <fa-icon [icon]="faDownload" class="pl-4"></fa-icon>
            Product By Digital Channel
         </span>
      </p>
   </div>
   <div class="containerCardReview">
      <div class="card reviewCard" *ngIf="getSupplierCategoryPerformance.length > 0">
         <div class="card__content" *ngIf="getSupplierCategoryPerformanceLoaded">
            <div class="row mb-2">
               <div class="col-4">
                  <p class="cardText text-left">
                     Article Description
                  </p>
               </div>
               <div class="col-2">
                  <p class="cardText">
                     Category
                  </p>
               </div>
               <div class="col-1">
                  <p class="cardText cursor " (click)="sortCategory('RATE')">
                     Rating
                     <span *ngIf="RATEDesc" class="noPrint">▼</span>
                     <span *ngIf="RATEAsc" class="noPrint">▲</span>
                  </p>
               </div>
               <div class="col-1">
                  <p class="cardText cursor " (click)="sortCategory('REV')">
                     Revenue
                     <span *ngIf="REVDesc" class="noPrint">▼</span>
                     <span *ngIf="REVAsc" class="noPrint">▲</span>
                  </p>
               </div>
               <div class="col-1">
                  <p class="cardText cursor " (click)="sortCategory('UNIT')">
                     Units Sold
                     <span *ngIf="UNITDesc" class="noPrint">▼</span>
                     <span *ngIf="UNITAsc" class="noPrint">▲</span>
                  </p>
               </div>
               <div class="col-1">
                  <p class="cardText cursor " (click)="sortCategory('ORD')">
                     Orders
                     <span *ngIf="ORDDesc" class="noPrint">▼</span>
                     <span *ngIf="ORDAsc" class="noPrint">▲</span>
                  </p>
               </div>
               <div class="col-1">
                  <p class="cardText cursor " (click)="sortCategory('PDP')">
                     PDP Views
                     <span *ngIf="PDPDesc" class="noPrint">▼</span>
                     <span *ngIf="PDPAsc" class="noPrint">▲</span>
                  </p>
               </div>
               <div class="col-1">
                  <p class="cardText cursor " (click)="sortCategory('CONV')">
                     Conversion
                     <span *ngIf="convDesc" class="noPrint">▼</span>
                     <span *ngIf="convAsc" class="noPrint">▲</span>
                  </p>
               </div>
            </div>
            <div class="scroll" *ngIf="sort">
               <div class="row space" *ngFor="let perf of getSupplierCategoryPerformance; let i = index">
                  <div class="col-4">
                     <p class="cardValue text-left" [ngClass]="{
                     'top4': (i>=0 && i<4),
                     'top7': (i>=4 && i<7),
                     'top10': (i>=7)
                     }">
                        {{i + 1}}. {{perf.product}} ({{perf.product_id}})
                     </p>
                  </div>
                  <div class="col-2">
                     <p class="cardValue" [ngClass]="{
                     'top4': (i>=0 && i<4),
                     'top7': (i>=4 && i<7),
                     'top10': (i>=7)
                     }">
                        {{perf.category}}
                     </p>
                  </div>
                  <div class="col-1">
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="perf.ratings || perf.ratings == 0">
                        {{perf.ratings | number: '.0-1'}}
                     </p>
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="!perf.ratings && perf.ratings != 0">
                        NA
                     </p>
                  </div>
                  <div class="col-1">
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'D'">
                        ${{perf.ty_demand_revenue | number: '.0-0'}}
                     </p>
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'F'">
                        ${{perf.ty_fulfilled_revenue | number: '.0-0'}}
                     </p>
                  </div>
                  <div class="col-1">
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'D'">
                        {{perf.ty_demand_quantity | number: '.0-0'}}
                     </p>
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'F'">
                        {{perf.ty_fulfilled_quantity | number: '.0-0'}}
                     </p>
                  </div>
                  <div class="col-1">
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'D'">
                        {{perf.ty_demand_orders | number: '.0-0'}}
                     </p>
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'F'">
                        {{perf.ty_fulfilled_orders | number: '.0-0'}}
                     </p>
                  </div>
                  <div class="col-1">
                     <p class="cardValue" [ngClass]="{
                     'top4': (i>=0 && i<4),
                     'top7': (i>=4 && i<7),
                     'top10': (i>=7)
                     }">
                        {{perf.ty_traffic | number: '.0-0'}}
                     </p>
                  </div>
                  <div class="col-1">
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'D'">
                        {{perf.ty_demand_conversion | number: '.0-2'}}%
                     </p>
                     <p class="cardValue" [ngClass]="{
                  'top4': (i>=0 && i<4),
                  'top7': (i>=4 && i<7),
                  'top10': (i>=7)
                  }" *ngIf="type == 'F'">
                        {{perf.ty_fulfilled_conversion | number: '.0-2'}}%
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <div class="card__content" *ngIf="!getSupplierCategoryPerformanceLoaded">
            <div class="loader"> </div>
            <p class="cardText text-center mb-3">
               Loading
            </p>
         </div>
      </div>
      <div class="card reviewCard" *ngIf="getSupplierCategoryPerformance.length == 0">
         <div class="card__content" *ngIf="getSupplierCategoryPerformanceLoaded">
            <p class="widgetHeading text-center my-3">
               Data not available
            </p>
         </div>
      </div>
   </div>
   <!-- traffic and conversion and types-->
   <div class="col-12" *ngIf="type == 'D'">
      <p class="widgetHeading">
         Traffic (for demand only)
         <span class="right cursor" (click)="downloadConversionTraffic()">
            <fa-icon [icon]="faDownload" class="pl-4"></fa-icon>
            Download
         </span>
      </p>
   </div>
   <div class="containerCardReview" *ngIf="type == 'D'">
      <div class="card reviewCard" *ngIf="getConversionTraffic.length > 0">
         <div class="card__content" *ngIf="getConversionTrafficLoaded && !getConversionTrafficEmpty">
            <div class="row space">
               <div class="col-6">
                  <p class="cardText">
                  </p>
               </div>
               <div class="col-3">
                  <p class="cardText">
                     Traffic
                  </p>
               </div>
               <div class="col-3">
                  <p class="cardText">
                     vs LY
                  </p>
               </div>
               <!-- <div class="col-2">
               <p class="cardText">
                  Conversion 
               </p>
            </div>
            <div class="col-2">
               <p class="cardText">
                  vs LY
               </p>
            </div> -->
            </div>
            <div class="row space" *ngFor="let item of getConversionTraffic; let i = index">
               <div class="col-6">
                  <p class="cardValue text-left">
                     {{item.channel_grouping}}
                  </p>
               </div>
               <div class="col-3">
                  <p class="cardValue">
                     {{item.ty_traffic | number: '.0-0'}}
                  </p>
               </div>
               <div class="col-3">
                  <p class="cardValue" *ngIf="item.vs_ly_traffic"
                     [ngStyle]="{'color': (item.vs_ly_traffic >= 0) ? '#3BE05F' : '#ff5c5d'}">
                     {{item.vs_ly_traffic | number: '.0-2'}}%
                  </p>
                  <p class="cardValue" *ngIf="!item.vs_ly_traffic">
                     NA
                  </p>
               </div>
               <!-- <div class="col-2">
               <p class="cardValue">
                  {{item.ty_conversion_dem | number: '.0-2'}}%
               </p>
            </div>
            <div class="col-2">
               <p class="cardValue">
                  NA
               </p>
            </div> -->
               <div class="col-12">
                  <hr class="lines">
               </div>
            </div>
         </div>
         <div class="card__content" *ngIf="getConversionTrafficLoaded && getConversionTrafficEmpty">
            <p class="widgetHeading text-center my-3">
               Data not available
            </p>
         </div>
         <div class="card__content" *ngIf="!getConversionTrafficLoaded">
            <div class="loader"> </div>
            <p class="cardText text-center mb-3">
               Loading
            </p>
         </div>
      </div>
      <div class="card reviewCard" *ngIf="getConversionTraffic.length == 0">
         <div class="card__content" *ngIf="getConversionTrafficLoaded">
            <p class="widgetHeading text-center my-3">
               Data not available
            </p>
         </div>
      </div>
   </div>
   <!-- Member Stats -->
   <div class="col-12" *ngIf="type == 'D'">
      <p class="widgetHeading mb-3">
         Member Stats
      </p>
   </div>
   <div class="containerCardReview" *ngIf="type == 'D'">
      <div class="card__content" *ngIf="getSupplierMembersLoaded">
         <div class="row">
            <div class="col-6">
               <div class="card">
                  <div class="card__content">
                     <h1 class="cardValue">First time members
                        <span class="cardText">
                           (shopped in last 90 days)
                        </span>
                     </h1>
                     <div class="row">
                        <div class="col-12">
                           <p class="card__title text-center mt-4">
                              {{getSupplierMembers?.NEW_MEMBERS[0]?.new_member | number: '.0-0'}}
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-6">
               <div class="card">
                  <div class="card__content">
                     <h1 class="cardValue">Repeat members
                        <span class="cardText">
                           (shopped in last 90 days)
                        </span>
                     </h1>
                     <div class="row">
                        <div class="col-12">
                           <p class="card__title text-center mt-4">
                              {{getSupplierMembers?.REPEATED_MEMBERS[0]?.returning_member | number: '.0-0'}}
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="card__content" *ngIf="!getSupplierMembersLoaded">
         <div class="loader"> </div>
         <p class="cardText text-center mb-3">
            Loading
         </p>
      </div>
   </div>
   <!-- Funnel View -->
   <!-- <div class="col-12">
   <p class="widgetHeading">
      Funnel View
   </p>
</div>
<div class="containerCardReview">
   <div class="card reviewCard">
      <div class="card__content">
         <div class="row">
            <div class="col-12 pl-0">
               <div class="skills">
                  <div class="charts">
                     <div class="chart chart--dev">
                        <ul class="chart--horiz">
                           <li>
                              <span class="chart__label">PDP</span>
                              (<span *ngIf="getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.pdp_view" class="chart__label pl-0">
                                 {{getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].pdp_view | number}}
                              </span>
                              <span *ngIf="!getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.pdp_view" class="chart__label pl-0">
                                 0
                              </span>
                              <span class="cardText" *ngIf="getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_pdp_view"
                                 [ngStyle]="{'color': (getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_pdp_view >= 0) ? '#3BE05F' : '#ff5c5d'}">
                                 {{getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_pdp_view | number: '.0-2'}}% vs LY
                              </span>)
                           </li>
                           <li class="chart__bar noPrint PDPChart" [style.width.%]="pdpWidth"></li>
                           <li>
                              <span class="chart__label">
                                 Add to Cart/Checkout
                                 </span>
                                 (<span *ngIf="getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.add_to_cart" class="chart__label pl-0">
                                 {{getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].add_to_cart | number}}
                                 </span>
                                 <span *ngIf="!getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.add_to_cart" class="chart__label pl-0">
                                 0
                                 </span>
                                 <span class="cardText" *ngIf="getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_add_to_cart"
                                 [ngStyle]="{'color': (getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_add_to_cart >= 0) ? '#3BE05F' : '#ff5c5d'}">
                                 {{getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_add_to_cart | number: '.0-2'}}% vsLY</span>
                                 )
                           </li>
                           <li class="chart__bar noPrint ATCChart" [style.width.%]="atcWidth">
                           </li>
                           <li>
                              <span class="chart__label">
                                 Orders
                                 </span>
                                 (<span *ngIf="getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.orders" class="chart__label pl-0">
                                 {{getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0].orders | number}}
                                 </span>
                                 <span *ngIf="!getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.orders" class="chart__label pl-0">
                                 0
                                 </span>
                                 <span class="cardText" *ngIf="getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_orders"
                                 [ngStyle]="{'color': (getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_orders >= 0) ? '#3BE05F' : '#ff5c5d'}">
                                 {{getSupplierFunnel.SUPPLIER_FUNNEL_TABLE_DATA[0]?.vs_ly_orders | number: '.0-2'}}% vsLY</span>
                                 )
                           </li>
                           <li class="chart__bar noPrint OrderChart" [style.width.%]="orderWidth">
                           
                           </li>
                           
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div> -->
</div>
<!-- Supplier Change Modal -->
<div class="modal" [ngClass]="{'modalDisplay': showDigitalModal}">
   <div class="modal-dialog modal-width-60">
      <div class="modal-content">
         <!-- Modal body -->
         <div class="modal-body">
            <div class="row noMar">
               <div class="col-2">
                  <div class="header">
                     Select
                  </div>
               </div>
               <div class="col-10">
                  <input [(ngModel)]="searchText" placeholder="search text goes here" class="search">
                  <button type="button" class="active close" data-dismiss="modal"
                     (click)="showDigitalModal = !showDigitalModal">&times;</button>
               </div>
               <div class="modalScroll">
                  <div class="col-12" *ngFor="let item of getSupplierList | filter : searchText">
                     <p class="cardValue text-left cursor" (click)="changeSupplier(item.supplier_name)">
                        {{item.supplier_name}}
                     </p>
                     <hr class="lines">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- Feedback  -->
<div class="modal" [ngClass]="{'modalDisplay': openFeedbackModal}">
   <div class="modal-dialog modal-width-60">
      <div class="modal-content">
         <!-- Modal body -->
         <div class="modal-body">
            <div class="row noMar">
               <div class="col-10">
                  <div class="header">
                     Feedback
                  </div>
               </div>
               <div class="col-2">
                  <button type="button" class="close active" data-dismiss="modal"
                     (click)="openFeedbackModal = !openFeedbackModal">&times;</button>
               </div>
               <div class="col-12">
                  <div class="form-group">
                     <textarea class="form-control" class="feedbackBox" rows="3" [(ngModel)]="message"
                        placeholder=" We love hearing from you, let us know what you think..."></textarea>
                  </div>
               </div>
               <div class="col-12 mt-1">
                  <button type="button" class="btn btn-outline-light btn-block"
                     (click)="feedbackResponse()">Submit</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- Orders Modal  -->
<div class="modal" [ngClass]="{'modalDisplay': openOrdersModal}">
   <div class="modal-dialog modal-width-60">
      <div class="modal-content">
         <!-- Modal body -->
         <div class="modal-body">
            <div class="row">
               <div class="col-10">
                  <div class="header">
                     Order Details
                  </div>
               </div>
               <div class="col-2">
                  <button type="button" class="close active" data-dismiss="modal"
                     (click)="openOrdersModal = !openOrdersModal">&times;</button>
               </div>
            </div>
            <div class="row">
               <div class="col-6">
                  <p class="cardText text-left">
                     Products
                  </p>
               </div>
               <div class="col-2">
                  <p class="cardText cursor" (click)="sortOrderModal('REV')">
                     Revenue
                     <span *ngIf="ordModalRevDesc">▼</span>
                     <span *ngIf="ordModalRevAsc">▲</span>
                  </p>
               </div>
               <div class="col-2">
                  <p class="cardText cursor" (click)="sortOrderModal('UNITS')">
                     Units Sold
                     <span *ngIf="ordModalUnitsDesc">▼</span>
                     <span *ngIf="ordModalUnitsAsc">▲</span>
                  </p>
               </div>
               <div class="col-2">
                  <p class="cardText cursor" (click)="sortOrderModal('ORD')">
                     Orders
                     <span *ngIf="ordModalOrdDesc">▼</span>
                     <span *ngIf="ordModalOrdAsc">▲</span>
                  </p>
               </div>
            </div>
            <div class="scroll" *ngIf="openOrdersModal && getSupplierTotalOrderPopupLoaded">
               <div class="row" *ngFor="let rate of getSupplierTotalOrderPopup; let i = index">
                  <div class="col-6">
                     <p class="cardValue text-left" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{i+1}}.{{rate.product}}({{rate.product_id}})
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'D'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        ${{rate.ty_demand_revenue}}
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'F'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        ${{rate.ty_fulfilled_revenue}}
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'D'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{rate.ty_demand_quantity}}
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'F'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{rate.ty_fulfilled_quantity}}
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'D'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{rate.ty_demand_orders}}
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'F'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{rate.ty_fulfilled_orders}}
                     </p>
                  </div>
               </div>
            </div>
            <div class="row" *ngIf="!getSupplierTotalOrderPopupLoaded">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- Ratings Modal  -->
<div class="modal" [ngClass]="{'modalDisplay': openRatingsModal}">
   <div class="modal-dialog modal-width-60">
      <div class="modal-content">
         <!-- Modal body -->
         <div class="modal-body">
            <div class="row">
               <div class="col-10">
                  <div class="header">
                     Ratings
                  </div>
               </div>
               <div class="col-2">
                  <button type="button" class="close active" data-dismiss="modal"
                     (click)="openRatingsModal = !openRatingsModal">&times;</button>
               </div>
            </div>
            <div class="row">
               <div class="col-8">
                  <p class="cardText text-left">
                     Products
                  </p>
               </div>
               <div class="col-2">
                  <p class="cardText cursor" (click)="sortRatingModal('UNITS')">
                     Units Sold
                     <span *ngIf="unitRatModalDesc">▼</span>
                     <span *ngIf="unitRatModalAsc">▲</span>
                  </p>
               </div>
               <div class="col-2">
                  <p class="cardText cursor" (click)="sortRatingModal('REVIEW')">
                     Reviews
                     <span *ngIf="revRatModalDesc">▼</span>
                     <span *ngIf="revRatModalAsc">▲</span>
                  </p>
               </div>
            </div>
            <div class="scroll" *ngIf="openRatingsModal && getSupplierRatingPopupLoaded">
               <div class="row" *ngFor="let rate of getSupplierRatingPopup; let i = index">
                  <div class="col-8">
                     <p class="cardValue text-left" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{i+1}}.{{rate.article_desc}}({{rate.article_id}})
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'D'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{rate.units_d}}
                     </p>
                  </div>
                  <div class="col-2" *ngIf="type == 'F'">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{rate.units_f}}
                     </p>
                  </div>
                  <div class="col-2">
                     <p class="cardValue" [ngClass]="{
                        'top4': (i>=0 && i<4),
                        'top7': (i>=4 && i<7),
                        'top10': (i>=7)
                        }">
                        {{rate.reviews}}
                     </p>
                  </div>
               </div>
            </div>
            <div class="row" *ngIf="!getSupplierRatingPopupLoaded">
               <div class="col-12">
                  <div class="loader"> </div>
                  <p class="cardText text-center mb-3">
                     Loading
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>