import { Component, OnInit } from '@angular/core';
import { faKey, faExclamationTriangle, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SupplierService } from '../services/supplier.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  faKey = faKey;
  faExclamationTriangle = faExclamationTriangle;
  faCheckSquare = faCheckSquare;
  disabled = false;
  hash = '';
  password = '';
  confirmPassword = '';
  isIncorrect = false;
  serverError = false;
  passwordValid = false;
  confpasswordValid = false;
  passwordMismatch = false;
  passwordResetValid = false;
  body: any;
  validhash = false;
  hashBody: any;
  constructor(private router: Router, private route: ActivatedRoute, private supplierService:SupplierService) { }

  ngOnInit() {
    this.hash = this.route.snapshot.queryParamMap.get("hash");
    this.hashBody = {
      "hashToken": this.hash
    }
    this.supplierService.validateHash(this.hashBody).subscribe(
      (data) => {
        if(data['status'] == 'vaild'){
          this.validhash = true;
        } else {
          this.validhash = false;
        }
      },
      (error) => {
        this.validhash = false;
        console.log(error);
      }
    );
  }

  validatePassword(e){
    var re = /^([a-zA-Z0-9_@!&$]){8,50}$/;
    return re.test(e);
  }

  submit(){
    this.isIncorrect = false;
    this.passwordMismatch = false;
    this.passwordResetValid = false;
    this.serverError = false;
    this.passwordValid = this.validatePassword(this.password);
    this.confpasswordValid =  this.validatePassword(this.confirmPassword);
    if(!this.confpasswordValid  || !this.passwordValid){
      this.isIncorrect = true;
    } else if(this.password !== this.confirmPassword){
      this.passwordMismatch = true;
    } else {
      this.isIncorrect = false;
      this.passwordMismatch = false;
      this.reset();
    }
  }

  reset(){
    this.disabled = true;
    this.passwordResetValid = false;
    this.serverError = false;
    this.body = {
      "newPassword": this.password,
      "confirmNewPassword" : this.confirmPassword,
      "hashToken": this.hash
    }
    this.supplierService.passwordReset(this.body).subscribe(
      (data) => {
        if(data['PASSWORD_UPDATE'] == 'successful'){
          this.passwordResetValid = true;
          this.serverError = false;
        } else {
          this.passwordResetValid = false;
          this.serverError = true;
        }
        this.disabled = false;
      },
      (error) => {
        this.passwordResetValid = false;
        this.disabled = false;
        this.serverError = true;
        console.log(error);
      }
    );
  }

  navigateToLogin(){
    this.router.navigateByUrl('/login');
  }

}
