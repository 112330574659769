import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './home/home.component';
import { ContentComponent } from './content/content.component';
import { ResetComponent } from './reset/reset.component';
import { SupplierService } from './services/supplier.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AuthGuardService } from './services/auth-guard.service';
import { ErrorComponent } from './error/error.component';
import { FilterPipe } from './Pipes/filter.pipe';
import { GoogleChartsModule } from 'angular-google-charts';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ContentComponent,
    ResetComponent,
    FilterPipe,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    FontAwesomeModule,
    AngularMyDatePickerModule,
    GoogleChartsModule,
    RecaptchaModule
  ],
  providers: [
    SupplierService,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
