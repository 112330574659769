<div class="page">
  <div class="container">
    <div class="left" *ngIf="!authPage">
      <div class="row">
        <div class="col-12">
          <img src="../../assets/images/2020_GameOn.png" alt="Game On 2020" class="img-responsive gameOn">
        </div>
      </div>
      <div class="login">Login</div>
      <div class="eula invalid" *ngIf="isIncorrect">
        Please enter a valid email id and then submit
      </div>
      <div class="eula invalid" *ngIf="isFailed">
        Sorry, We are facing some issue trying to find your email, Please contact administrator.
      </div>
      <div class="eula" *ngIf="!isIncorrect && !isFailed">
        By logging in you agree to the terms and conditions of BJ's Wholesale
      </div>
    </div>
    <div class="left" *ngIf="authPage">
      <div class="row">
        <div class="col-12">
          <img src="../../assets/images/2020_GameOn.png" alt="Game On 2020" class="img-responsive gameOn">
        </div>
      </div>
      <div class="login">Enter Authentication code</div>
      <div class="eula my-0 valid" *ngIf="!isAuthFailed">
        We have send you an email with a 6 digit authentication code.<br>
        <span (click)="resend()" class="cursor link" *ngIf="!resendStart">Resend Code</span>
        <span class="link" *ngIf="resendStart">Sending Code...</span>
      </div>
      <div class="eula invalid" *ngIf="isAuthFailed">
        The code entered is invalid. Please recheck and try again.
      </div>

    </div>
    <div class="right" [ngClass]="{'transistion': step != 0}" *ngIf="!authPage">
      <div class="form">
        <label for="email"> Email</label>
        <input type="email" id="email" class="border-bottom" autocomplete="off" [(ngModel)]="email" name="email"
          #pickedName="ngModel" (keydown.enter)="submitdata()">
        <div class="recaptcha mt-4">
          <re-captcha (resolved)="resolved($event)" siteKey="6Lcu1qcZAAAAAKmxmKjgbDQynsu7BmsVHYAI1Hnw"></re-captcha>
        </div>
        <input type="submit" id="submit" value="Submit" (click)="submit()" *ngIf="!disabled && notBotUser">
        <div *ngIf="disabled">
          <div class="loader"> </div>
        </div>
      </div>
    </div>
    <div class="right" [ngClass]="{'transistion': step != 0}" *ngIf="authPage">
      <div class="form">
        <label for="email">Authcode</label>
        <input type="password" id="authcode" class="border-bottom" autocomplete="off" [(ngModel)]="authcode"
          name="reset" #pickedName="ngModel" (keydown.enter)="login()">
        <div class="height"></div>
        <label for="forget" class="forgot" (click)="toggle()">
          < Back to Email</label>
            <input type="submit" id="submit" value="Login" (click)="login()" *ngIf="!disabled">
            <div *ngIf="disabled">
              <div class="loader"> </div>
            </div>
      </div>
    </div>
  </div>
</div>