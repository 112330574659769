export const envConst = {
    getTimePeriods: 'getTimePeriods',
    getDataRefreshTime: 'getDataRefreshFlag',
    getSupplierList: 'getSupplierList',

    getSupplierRevenueOrderTrend: 'supplier/getSupplierRevenueOrderTrend',
    getSupplierRevenueOrderTrendFulfill: 'supplierFulfilled/getSupplierRevenueOrderTrendFulfill',

    getSupplierPenetration: 'supplier/getSupplierPenetration',
    getSupplierPenetrationFulfilled: 'supplierFulfilled/getSupplierPenetrationFulfilled',

    getSupplierSalesPerformance: 'supplier/getSupplierSalesPerformance',
    getSupplierSalesPerformanceFulfilled: 'supplierFulfilled/getSupplierSalesPerformanceFulfilled',

    getSupplierDigitalChannelStatsItemTotal: 'supplier/getSupplierDigitalChannelStatsItemTotal',

    getSupplierRating: 'supplier/getSupplierRating',
    getSupplierRatingFulfilled: 'supplierFulfilled/getSupplierRatingFulfilled',

    getSupplierDigitalChannelStats: 'supplier/getSupplierDigitalChannelStats',
    getSupplierDigitalChannelStatsFulfilled: 'supplierFulfilled/getSupplierDigitalChannelStatsFulfilled',

    getSupplierCategoryPerformance: 'supplier/getSupplierCategoryPerformance',
    getSupplierCategoryPerformanceFulfilled: 'supplierFulfilled/getSupplierCategoryPerformanceFulfilled',

    getSupplierTotalOrderPopup: 'supplier/getSupplierTotalOrderPopup',
    getSupplierTotalOrderPopupFulfilled: 'supplierFulfilled/getSupplierTotalOrderPopupFulfilled',
    getSupplierRatingPopupFulfilled: 'supplierFulfilled/getSupplierRatingPopupFulfilled',
    getConversionTraffic: 'supplier/getConversionTraffic',
    getSupplierMembers: 'supplier/getSupplierMembers',
    getSupplierFunnel: 'supplier/getSupplierFunnel',
    getSupplierRatingPopup: 'supplier/getSupplierRatingPopup',
    login: 'loginUsingAuth',
    sendLoginCode: 'sendLoginCode',
    logout: 'logout',
    getLevel4Mapping: 'getLevel4Mapping',
    sendResetPasswordLink: 'sendResetPasswordLink',
    passwordReset: 'passwordReset',
    validateHash: 'validateHash',
    sendFeedbackMessage: 'sendFeedbackMessage',
    activity: 'supplier/activity',
    activityf: 'supplierFulfilled/activity'
}